import { fetchJson } from './Base';
import { PaginatedListResponse, DataResponse, Response } from '../contract/Response';
import { Nullable, int } from '../util/Types';
import { ContactForm } from '../contract/dto/ContactForm';
import queryString from 'query-string';

export type GetContactFormsResponse = PaginatedListResponse<ContactForm>;
export async function getContactForms(
    pageIndex: int = 0,
    pageLength: int = 50
): Promise<Nullable<GetContactFormsResponse>> {
    let query = queryString.stringify({
        pageIndex: pageIndex,
        pageLength: pageLength
    });

    return await fetchJson<GetContactFormsResponse>(`/api/contact-forms?${query}`, {
        method: 'GET'
    });
}

export type GetContactFormByIdResponse = DataResponse<ContactForm>;
export async function getContactFormById(id: int): Promise<Nullable<GetContactFormByIdResponse>> {
    return await fetchJson<GetContactFormByIdResponse>(`/api/contact-forms/${id}`, {
        method: 'GET'
    });
}

export type DeleteContactFormResponse = Response;
export async function deleteContactForm(
    id: int
): Promise<Nullable<DeleteContactFormResponse>> {
    return await fetchJson<DeleteContactFormResponse>(`/api/contact-forms/${id}`, {
        method: 'DELETE'
    });
}