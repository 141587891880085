import { int, String } from '../util/Types';

export interface Error {
    statusCode: int;
    errorCode: String;
    errorMessage: String;
}

export interface Response {
    statusCode: int;
    errorCode: String;
    errorMessage: String;
    errors: Error[];
    success: boolean;
}

export interface DataResponse<T> extends Response {
    data: T;
}

export interface ListResponse<T> extends DataResponse<T[]> {
}

export interface PaginatedListResponse<T> extends ListResponse<T> {
    totalCount: int;
}

export function newError(
    statusCode: int,
    errorCode: string,
    errorMessage: string): Error {
    return {
        errorCode,
        errorMessage,
        statusCode
    };
}

export function newErrorResponse(
    statusCode: int, 
    errorCode: string,
    errorMessage: string): Response {
    return {
        errorCode,
        errorMessage,
        errors: [newError(statusCode, errorCode, errorMessage)],
        statusCode,
        success: false
    };
}