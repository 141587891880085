import * as React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

type DialogProps = React.PropsWithChildren<{
    title: string;
    isOpen: boolean;
    onClose: () => void;
}>;

export default (props: DialogProps) => (
    <Modal size="lg" isOpen={props.isOpen} toggle={props.onClose} className="modal-dialog-centered">
        <ModalHeader toggle={props.onClose}>{props.title}</ModalHeader>
        <ModalBody>
            {props.children}
        </ModalBody>
        <ModalFooter>
            <Button color="secondary" onClick={props.onClose}>Tamam</Button>
        </ModalFooter>
    </Modal>
);