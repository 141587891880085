import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { Dispatch, bindActionCreators, Action } from 'redux';
import { Table, Button, ButtonGroup } from 'reactstrap';
import BlockSpinner from '../components/BlockSpinner';
import { withToastUI } from '../components/Toast';
import { Link } from 'react-router-dom';
import { Routes } from '../Routes';
import { BasePage, BasePageProps } from './BasePage';
import Prompt from '../components/Prompt';
import { DeleteCategoryResponse, deleteCategory } from '../services/Category';
import { Nullable, int } from '../util/Types';
import { DeletePropertyResponse, deleteProperty, getProperties } from '../services/Property';
import { Property } from '../contract/dto/Property';

const mapStateToProps = (state: ApplicationState) => ({
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
    bindActionCreators(
        {
        },
        dispatch
    );

type PropertiesProps =
    BasePageProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {

    };

type PropertiesState = {
    loadingProperties: boolean;
    properties: Nullable<Property[]>;
    propertyToDelete: Nullable<int>;
    deleting: boolean;
    deleteResponse: Nullable<DeletePropertyResponse>;
};

class Properties extends BasePage<PropertiesProps, PropertiesState> {
    constructor(props: PropertiesProps) {
        super(props);

        this.state = {
            loadingProperties: false,
            properties: [],
            propertyToDelete: null,
            deleting: false,
            deleteResponse: null
        };
    }

    async componentDidMount() {
        this.setPageTitle('Özellik Tipleri');

        await this.fetchData();
    }

    private async fetchData() {
        this.setState({
            loadingProperties: true,
            properties: null
        });

        let response = await getProperties();

        this.setState({
            loadingProperties: false
        });

        if (response && response.success) {
            this.setState({
                properties: response.data
            });
        }
        else {
            this.handleError(response);
        }
    }


    async deleteProperty() {
        if (!this.state.propertyToDelete) {
            return;
        }

        this.setState({
            deleting: true,
            deleteResponse: null
        });

        let response = await deleteProperty(this.state.propertyToDelete);

        this.setState({
            deleting: false,
            deleteResponse: response,
            propertyToDelete: null
        });

        if (response && response.success) {
            this.props.push(
                'Başarılı',
                'Özellik tipi silindi.',
                'success');

            this.fetchData();
        }
        else {
            this.handleError(response);
        }
    }

    public render() {
        return (
            <>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Özellik Tipleri</h1>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <Link to={Routes.properties.new()} className="btn btn-outline-primary btn-sm">
                            <i className="fa fa-plus mr-2"></i>Oluştur
                        </Link>
                    </div>
                </div>

                <div className="table-responsive">
                    <Table responsive striped>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Ad</th>
                                <th>İşlem</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(!this.state.loadingProperties && this.state.properties) && this.state.properties.map((property, i) => (
                                <tr key={i}>
                                    <th scope="row">{property.id}</th>
                                    <td>{property.name}</td>
                                    <td>
                                        <ButtonGroup>
                                            <Link to={Routes.properties.edit(property.id)} className="btn btn-sm btn-info"><i className="fa fa-edit"></i></Link>
                                            <Button size="sm" color="danger" onClick={() => this.setState({ propertyToDelete: property.id })}><i className="fa fa-trash"></i></Button>
                                        </ButtonGroup>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>

                {(this.state.loadingProperties || this.state.deleting) && (<BlockSpinner />)}

                <Prompt
                    title="Özellik Tipi Sil"
                    text="Özellik tipi silinecek, bu işlem geri alınamaz. Emin misiniz?"
                    isOpen={this.state.propertyToDelete !== null}
                    onClose={() => this.setState({ propertyToDelete: null })}
                    onSubmit={async () => await this.deleteProperty()}
                />
            </>
        );
    }
}

export default withToastUI(connect(
    mapStateToProps,
    mapDispatchToProps
)(Properties));