import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as CategoryStore from '../store/Category';
import { Dispatch, bindActionCreators } from 'redux';
import { Button } from 'reactstrap';
import BlockSpinner from '../components/BlockSpinner';
import { withToastUI } from '../components/Toast';
import CategoryForm, { CategoryFormFields } from '../forms/CategoryForm';
import { AddCategoryResponse, addCategory, getCategoryById, GetCategoryByIdResponse, UpdateCategoryResponse, updateCategory } from '../services/Category';
import { Nullable } from '../util/Types';
import { BasePage, BasePageProps } from './BasePage';
import { Category } from '../contract/dto/Category';
import { RouteComponentProps } from 'react-router';
import { uploadFile } from '../services/Upload';

const mapStateToProps = (state: ApplicationState) => ({
  ...state.category
});

const mapDispatchToProps = (dispatch: Dispatch<CategoryStore.KnownAction>) =>
  bindActionCreators(
    {
      ...CategoryStore.actionCreators
    },
    dispatch
  );

type EditCategoryProps =
  RouteComponentProps<{ id: string; }> &
  BasePageProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {

  };

type EditCategoryState = {
  loadingCategory: boolean;
  category: Nullable<Category>;
  categoryResponse: Nullable<GetCategoryByIdResponse>;
  loading: boolean;
  response: Nullable<UpdateCategoryResponse>;
};

class EditCategory extends BasePage<EditCategoryProps, EditCategoryState> {
  constructor(props: EditCategoryProps) {
    super(props);

    this.state = {
      loadingCategory: false,
      category: null,
      categoryResponse: null,
      loading: false,
      response: null
    };
  }

  async componentDidMount() {
    this.setPageTitle('Kategori Düzenle');

    await this.fetchData();
  }

  private getId() {
    let id = parseInt(this.props.match.params.id);

    if (isNaN(id)) {
      return null;
    }

    return id;
  }

  private async fetchData() {
    let id = this.getId();
    if (!id) {
      return;
    }

    this.setState({
      loadingCategory: true,
      category: null,
      categoryResponse: null
    });

    let response = await getCategoryById(id);

    this.setState({
      loadingCategory: false,
      categoryResponse: response
    });

    if (response && response.success) {
      this.setState({
        category: response.data
      });
    }
    else {
      this.handleError(response);
    }
  }

  private async handleFormSubmit(data: CategoryFormFields) {
    let id = this.getId();
    if (!id) {
      return;
    }

    this.setState({
      loading: true,
      response: null
    });

    if (data.imageFile && data.imageFile.length) {
      const fileUploadResponse = await uploadFile({
        file: data.imageFile[0],
        folder: 'yaylaoglu.com/images/categories'
      });

      if (!fileUploadResponse || !fileUploadResponse.success) {
        this.handleError(fileUploadResponse);

        return;
      }

      // Set image url to upload result url
      data.imageUrl = fileUploadResponse.data.url;
    }

    let response = await updateCategory(
      id,
      {
        ...data
      });

    this.setState({
      loading: false,
      response: response
    });

    if (response && response.success) {
      this.props.push(
        'Başarılı',
        'Kategori güncellendi.',
        'success');

      this.props.requestCategories();
      this.redirect((routes) => routes.categories.index());
    }
    else {
      this.handleError(response);
    }
  }

  mapCategoryToFormValues(): Partial<CategoryFormFields> | undefined {
    if (this.state.loadingCategory ||
      !this.state.category) {
      return undefined;
    }
    const { category } = this.state;
    return {
      description: category.description,
      imageUrl: category.icon,
      name: category.name,
      rawContent: category.post ? category.post.rawContent : undefined,
      summary: category.post ? category.post.summary : undefined,
      uri: category.uri
    }
  }

  public render() {
    return (
      <>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">Kategori Düzenle</h1>
          <div className="btn-toolbar mb-2 mb-md-0">
            <Button size="sm" outline color="primary">
              <i className="fa fa-save"></i>{' '}Kaydet
            </Button>
          </div>
        </div>

        <div className="row">
          <div className="col-md-8">
            <CategoryForm
              categories={this.props.categories}
              disabled={this.state.loadingCategory || this.state.loading || !this.state.category}
              enableReinitialize
              initialValues={this.mapCategoryToFormValues()}
              onSubmit={(data: any) => this.handleFormSubmit(data)}
            >
              {(this.state.loading || this.state.loadingCategory) && (<BlockSpinner />)}
            </CategoryForm>
          </div>
        </div>
      </>
    );
  }
}

export default withToastUI(connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCategory));