import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { Dispatch, bindActionCreators, Action } from 'redux';
import { Button, ButtonGroup, Row, Card, CardImg, CardBody, CardTitle, CardText, CardFooter } from 'reactstrap';
import BlockSpinner from '../components/BlockSpinner';
import { withToastUI } from '../components/Toast';
import { Link } from 'react-router-dom';
import { Routes } from '../Routes';
import { BasePage, BasePageProps } from './BasePage';
import Prompt from '../components/Prompt';
import { Nullable, int } from '../util/Types';
import { Post } from '../contract/dto/Post';
import { getPosts, deletePost } from '../services/Post';

const mapStateToProps = (state: ApplicationState) => ({
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
    bindActionCreators(
        {
        },
        dispatch
    );

type PostsProps =
    BasePageProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {

    };

type PostsState = {
    loadingPosts: boolean;
    posts: Nullable<Post[]>;
    postToDelete: Nullable<int>;
    deleting: boolean;
};

class Posts extends BasePage<PostsProps, PostsState> {
    constructor(props: PostsProps) {
        super(props);

        this.state = {
            loadingPosts: false,
            posts: [],
            postToDelete: null,
            deleting: false
        };
    }

    async componentDidMount() {
        this.setPageTitle('Makaleler');
        
        await this.fetchData();
    }

    private async fetchData() {
        this.setState({
            loadingPosts: true,
            posts: null
        });

        let response = await getPosts();

        this.setState({
            loadingPosts: false
        });

        if (response && response.success) {
            this.setState({
                posts: response.data
            });
        }
        else {
            this.handleError(response);
        }
    }


    async deletePost() {
        if (!this.state.postToDelete) {
            return;
        }

        this.setState({
            deleting: true
        });

        let response = await deletePost(this.state.postToDelete);

        this.setState({
            deleting: false,
            postToDelete: null
        });

        if (response && response.success) {
            this.props.push(
                'Başarılı',
                'Makale silindi.',
                'success');

            this.fetchData();
        }
        else {
            this.handleError(response);
        }
    }

    public render() {
        return (
            <>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Makalaler</h1>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <Link to={Routes.posts.new()} className="btn btn-outline-primary btn-sm">
                            <i className="fa fa-plus mr-2"></i>Oluştur
                        </Link>
                    </div>
                </div>

                <Row className="row-cols-1 row-cols-md-4">
                    {(!this.state.loadingPosts && this.state.posts) && this.state.posts.map((post, i) => (
                        <div key={i} className="col mb-4">
                            <Card className="h-100">
                                <CardImg top src={post.thumbnailImageUrl} />
                                <CardBody>
                                    <CardTitle>{post.name}</CardTitle>
                                    <CardText>{post.summary}</CardText>
                                </CardBody>
                                <CardFooter>
                                    <ButtonGroup>
                                        <Link to={Routes.posts.edit(post.id)} className="btn btn-sm btn-info"><i className="fa fa-edit"></i></Link>
                                        <Button size="sm" color="danger" onClick={() => this.setState({ postToDelete: post.id })}><i className="fa fa-trash"></i></Button>
                                    </ButtonGroup>
                                </CardFooter>
                            </Card>
                        </div>
                    ))}
                </Row>

                {(this.state.loadingPosts || this.state.deleting) && (<BlockSpinner />)}

                <Prompt
                    title="Makale Sil"
                    text="Makale silinecek, bu işlem geri alınamaz. Emin misiniz?"
                    isOpen={this.state.postToDelete !== null}
                    onClose={() => this.setState({ postToDelete: null })}
                    onSubmit={async () => await this.deletePost()}
                />
            </>
        );
    }
}

export default withToastUI(connect(
    mapStateToProps,
    mapDispatchToProps
)(Posts));