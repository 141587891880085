import { fetchJson } from './Base';
import { ListResponse, DataResponse, Response } from '../contract/Response';
import { Nullable, int } from '../util/Types';
import { Product } from '../contract/dto/Product';
import { AddProductRequest } from '../contract/request/AddProductRequest';
import { UpdateProductRequest } from '../contract/request/UpdateProductRequest';

export type GetProductsByCategoryResponse = ListResponse<Product>;
export async function getProductsByCategory(
    categoryId: int
): Promise<Nullable<GetProductsByCategoryResponse>> {
    return await fetchJson<GetProductsByCategoryResponse>(`/api/categories/${categoryId}/products`, {
        method: 'GET'
    });
}

export type AddProductResponse = DataResponse<Product>;
export async function addProduct(
    request: AddProductRequest
): Promise<Nullable<AddProductResponse>> {
    return await fetchJson<AddProductResponse>(`/api/products`, {
        method: 'POST',
        body: JSON.stringify(request)
    });
}

export type GetProductByIdResponse = DataResponse<Product>;
export async function getProductById(id: int): Promise<Nullable<GetProductByIdResponse>> {
    return await fetchJson<GetProductByIdResponse>(`/api/products/${id}`, {
        method: 'GET'
    });
}

export type UpdateProductResponse = DataResponse<Product>;
export async function updateProduct(
    id: int,
    request: UpdateProductRequest
): Promise<Nullable<UpdateProductResponse>> {
    return await fetchJson<UpdateProductResponse>(`/api/products/${id}`, {
        method: 'PUT',
        body: JSON.stringify(request)
    });
}

export type DeleteProductResponse = Response;
export async function deleteProduct(
    id: int
): Promise<Nullable<DeleteProductResponse>> {
    return await fetchJson<DeleteProductResponse>(`/api/products/${id}`, {
        method: 'DELETE'
    });
}