import { Predicate } from './Types';
import { parse, stringify } from 'query-string';

export const isNullOrUndefined = (obj: any) => {
    if (obj === undefined) {
        return true;
    }

    if (obj === null) {
        return true;
    }

    return false;
};

export function isNullOrEmpty(obj: string | undefined | null) {
    return isNullOrUndefined(obj) || obj === '';
}

export function removeElement<TElement>(array: Array<TElement>, element: TElement): Array<TElement> {
    let index = array.indexOf(element);

    if (index > -1) {
        array.splice(index, 1);
    }

    return array;
}

export function isNullOr<T>(obj: T, condition: Predicate<T>) {
    if (isNullOrUndefined(obj)) {
        return true;
    }

    return condition(obj);
}

export enum LogLevel {
    Debug = 0,
    Info = 1,
    Warn = 2,
    Error = 3,
    Fatal = 4
}

// TODO: Use this everywhere.
export function appLog(
    logLevel: LogLevel,
    message?: any,
    ...optionalParams: any[]) {
    switch (logLevel) {
        case LogLevel.Debug:
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                console.log(message, optionalParams);
            }
            return;
        case LogLevel.Info:
            console.info(message, optionalParams);
            return;
        case LogLevel.Warn:
            console.warn(message, optionalParams);
            return;
        case LogLevel.Error:
            console.error(message, optionalParams);
            return;
        case LogLevel.Fatal:
            console.error(message, optionalParams);
            return;
    }
}

export const nameof = <T>(name: keyof T) => name;

export function parseEnum<T>(text: string): T {
    return parseInt(text as string) as unknown as T;
}

export function getFieldNameString(fieldName: string, prefix?: string) {
    if (isNullOrEmpty(prefix)) {
        return fieldName;
    }

    return `${prefix}.${fieldName}`;
}

export function getFieldName<T>(fieldName: keyof T, prefix?: string) {
    if (isNullOrEmpty(prefix)) {
        return fieldName;
    }

    return `${prefix}.${fieldName}`;
}

export function parseQueryString<T>(str: string) {
    return parse(str, {
        parseNumbers: true,
        parseBooleans: true
    }) as unknown as T;
}

export function withQueryString<T>(url: string, queryData: T) {
    if (!queryData) {
        return url;
    }

    let query = stringify(queryData);

    return `${url}?${query}`;
}

function getPathParts(pathName: string) {
    return pathName.split('/');
}

export function urlSchemeEqualsUrl(urlScheme: string, url: string) {
    let urlSchemePathParts = getPathParts(urlScheme);
    let urlPathParts = getPathParts(url);

    if (urlSchemePathParts.length !== urlPathParts.length) {
        return false;
    }

    for (let i = 0; i < urlSchemePathParts.length; i++) {
        const urlSchemePathPart = urlSchemePathParts[i];
        
        if (urlSchemePathPart.startsWith(':')) {
            continue;
        }
        
        if (urlSchemePathPart.toLowerCase() !== urlPathParts[i].toLowerCase()) {
            return false;
        }
    }

    return true;
}