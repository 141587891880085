import { fetchJson } from './Base';
import { ListResponse, DataResponse, Response } from '../contract/Response';
import { AddCategoryRequest } from '../contract/request/AddCategoryRequest';
import { UpdateCategoryRequest } from '../contract/request/UpdateCategoryRequest';
import { Nullable, int } from '../util/Types';
import { Category } from '../contract/dto/Category';

export type GetCategoriesResponse = ListResponse<Category>;
export async function getCategories(): Promise<Nullable<GetCategoriesResponse>> {
    return await fetchJson<GetCategoriesResponse>(`/api/categories`, {
        method: 'GET'
    });
}

export type AddCategoryResponse = DataResponse<Category>;
export async function addCategory(
    request: AddCategoryRequest
): Promise<Nullable<AddCategoryResponse>> {
    return await fetchJson<AddCategoryResponse>(`/api/categories`, {
        method: 'POST',
        body: JSON.stringify(request)
    });
}

export type GetCategoryByIdResponse = DataResponse<Category>;
export async function getCategoryById(id: int): Promise<Nullable<GetCategoryByIdResponse>> {
    return await fetchJson<GetCategoryByIdResponse>(`/api/categories/${id}`, {
        method: 'GET'
    });
}

export type UpdateCategoryResponse = DataResponse<Category>;
export async function updateCategory(
    id: int,
    request: UpdateCategoryRequest
): Promise<Nullable<UpdateCategoryResponse>> {
    return await fetchJson<UpdateCategoryResponse>(`/api/categories/${id}`, {
        method: 'PUT',
        body: JSON.stringify(request)
    });
}

export type DeleteCategoryResponse = Response;
export async function deleteCategory(
    id: int
): Promise<Nullable<DeleteCategoryResponse>> {
    return await fetchJson<DeleteCategoryResponse>(`/api/categories/${id}`, {
        method: 'DELETE'
    });
}