import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { Dispatch, bindActionCreators, Action } from 'redux';
import { withToastUI } from '../components/Toast';
import { BasePage, BasePageProps } from './BasePage';
import { FormGroup, Label, FormText, Form, Button, Input } from 'reactstrap';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { getFieldName } from '../util/Utils';
import { renderInput, renderFileInput } from '../components/FormElements';
import { requiredFile, fileFormat } from '../util/Validation';



import { uploadFile } from '../services/Upload';


export type TestFormFields = {
    file: FileList;
}

type TestFormFieldsProps = {
    prefix?: string;
};

export function TestFormFields(props: TestFormFieldsProps) {
    return (
        <React.Fragment>
            <FormGroup>
                <Label for="file" className="btn btn-default btn-primary btn-sm">Dosya Seç</Label>
                <Field
                    id="file"
                    name={`${getFieldName<TestFormFields>('file', props.prefix)}`}
                    component={renderFileInput}
                    label="Dosya"
                    validate={[requiredFile, fileFormat]}
                    type="file"
                    accept=".jpg,.jpeg,.png,.pdf,image/jpeg,image/png,application/pdf"
                    className="d-none"
                />
                <FormText color="muted">
                    Yalnızca jpg, png veya pdf formatı
                </FormText>
            </FormGroup>
        </React.Fragment>
    );
}

type _TestFormProps = TestFormFieldsProps & {
    disabled?: boolean;
};

export type TestFormProps = InjectedFormProps<TestFormFields, _TestFormProps> &
    _TestFormProps;

class _TestForm extends React.PureComponent<TestFormProps> {
    public render() {
        const { handleSubmit } = this.props;

        return (
            <Form onSubmit={(s) => handleSubmit(s)} noValidate disabled={this.props.disabled}>
                <TestFormFields prefix={this.props.prefix} />

                <Button type="submit" color="primary"><i className="fa fa-save mr-2"></i>Kaydet</Button>

                {this.props.children}
            </Form>
        );
    }
};

export const TestFormName: string = 'test';

const TestForm = reduxForm<TestFormFields, _TestFormProps>({
    form: TestFormName
})(_TestForm);






const mapStateToProps = (state: ApplicationState) => ({
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
    bindActionCreators(
        {
        },
        dispatch
    );

type HomeProps =
    BasePageProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {

    };

class Home extends BasePage<HomeProps> {
    async componentDidMount() {
        this.setPageTitle('Panel');
    }

    async handleSubmit(data: TestFormFields) {
        this.setState({
            loading: true,
            response: null
        });

        console.log('DATA', data);

        let response = await uploadFile({
            folder: 'yaylaoglu.com/images/products/brick',
            file: data.file[0]
        });

        console.log('RESPONSE', response);

        this.setState({
            loading: false,
            response: response
        });

        if (response && response.success) {
            this.props.push(
                'Başarılı',
                'Dosya yüklendi. URL: ' + response.data.url,
                'success');
        }
        else {
            this.handleError(response);
        }
    }

    public render() {
        return (
            <>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Panel</h1>
                </div>

                <TestForm
                    onSubmit={(data) => this.handleSubmit(data)}
                />
            </>
        );
    }
}

export default withToastUI(connect(
    mapStateToProps,
    mapDispatchToProps
)(Home));