import * as React from 'react';
import { connect } from 'react-redux';
import SignInForm, { SignInFormFields } from '../forms/SignInForm';
import { signIn, SignInResponse } from '../services/Account';
import { Nullable } from '../util/Types';
import { withToastUI } from '../components/Toast';
import BlockSpinner from '../components/BlockSpinner';
import { ApplicationState } from '../store';
import * as SystemStore from '../store/System';
import { bindActionCreators, Dispatch } from 'redux';
import { BasePage, BasePageProps } from './BasePage';

const mapStateToProps = (state: ApplicationState) => ({
    ...state.system
});

const mapDispatchToProps = (dispatch: Dispatch<SystemStore.KnownAction>) =>
    bindActionCreators(
        {
            ...SystemStore.actionCreators
        },
        dispatch
    );

type SignInProps =
    BasePageProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {

    };

type SignInState = {
    loading: boolean;
    response: Nullable<SignInResponse>;
};

class SignIn extends BasePage<SignInProps, SignInState> {
    constructor(props: SignInProps) {
        super(props);

        this.state = {
            loading: false,
            response: null
        };
    }

    componentDidUpdate() {
        this.setPageTitle('Kullanıcı Girişi');

        if (this.props.user) {
            this.redirectBack();
        }
    }

    private async handleFormSubmit(data: SignInFormFields) {
        this.setState({
            loading: true,
            response: null
        });

        let response = await signIn({
            email: data.login,
            password: data.password
        });

        this.setState({
            loading: false,
            response: response
        });

        if (response && response.success) {
            this.props.push(
                'Başarılı',
                'Giriş yapıldı, yönlendiriliyorsunuz...',
                'success');

            this.props.setUser(response.data);

            this.redirectBack();
        }
        else {
            this.handleError(response);
        }
    }

    public render() {
        return (
            <div className="d-flex h-100">
                <SignInForm
                    onSubmit={(data) => this.handleFormSubmit(data)}
                    disabled={this.state.loading}
                >
                    {this.state.loading && (<BlockSpinner />)}
                </SignInForm>
            </div>
        );
    }
}

export default withToastUI(connect(mapStateToProps, mapDispatchToProps)(SignIn));