import * as React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { renderInput } from '../components/FormElements';
import { required, minLength5, email } from '../util/Validation';
import { getFieldName } from '../util/Utils';
import { Form, Label, Input, Button, FormGroup } from 'reactstrap';

export type SignInFormFields = {
    login: string;
    password: string;
}

type SignInFormFieldsProps = {
    prefix?: string;
};

export function SignInFormFields(props: SignInFormFieldsProps) {
    return (
        <React.Fragment>
            <FormGroup>
                <Label for="email">Eposta adresi</Label>
                <Field
                    id="email"
                    name={`${getFieldName<SignInFormFields>('login', props.prefix)}`}
                    component={renderInput}
                    label="Eposta adresi"
                    validate={[required, email]}
                    type="email"
                />
            </FormGroup>

            <FormGroup>
                <Label for="password">Parola</Label>
                <Field
                    id="password"
                    name={`${getFieldName<SignInFormFields>('password', props.prefix)}`}
                    component={renderInput}
                    label="Parola"
                    validate={[required, minLength5]}
                    type="password"
                />
            </FormGroup>
        </React.Fragment>
    );
}

type _SignInFormProps = SignInFormFieldsProps & {
    disabled?: boolean;
};

export type SignInFormProps = InjectedFormProps<SignInFormFields, _SignInFormProps> &
    _SignInFormProps;

class SignInForm extends React.PureComponent<SignInFormProps> {
    public render() {
        const { handleSubmit } = this.props;

        return (
            <Form className="form-signin" onSubmit={(s) => handleSubmit(s)} noValidate disabled={this.props.disabled}>
                <h1 className="h3 mb-3 font-weight-normal">Giriş yapın</h1>

                <SignInFormFields prefix={this.props.prefix} />

                <FormGroup check className="mb-3">
                    <Label check>
                        <Input type="checkbox" id="checkbox2" />{' '}
                        Beni hatırla
                    </Label>
                </FormGroup>

                <Button type="submit" size="lg" color="primary" block>Giriş yap</Button>

                <p className="mt-5 mb-3 text-muted">© {new Date().getFullYear()}</p>

                {this.props.children}
            </Form>
        );
    }
};

export const SignInFormName: string = 'signIn';

export default reduxForm<SignInFormFields, _SignInFormProps>({
    form: SignInFormName
})(SignInForm);