import * as React from 'react';
import { Toast as RSToast, ToastHeader, ToastBody } from 'reactstrap';

type ToastData = {
    title: string;
    text: string;
    description?: string;
    color: string;
};

type _ToastData = ToastData & {
    id: number;
};

type ToastUIContextProps = {
    push: (toast: ToastData) => void;
};

const ToastUIContext = React.createContext<ToastUIContextProps>({
    push: (toast: ToastData) => { }
});

type Callback<T> = (state: T) => void;
type DispatchCallback<T> = (state: T, callback?: Callback<T>) => void;

function useStateCallback<TState>(initialState: TState): [TState, DispatchCallback<TState>] {
    const [state, setState] = React.useState<TState>(initialState);
    const cbRef = React.useRef<Callback<TState> | null>(null);

    const setStateCallback = (state: TState, cb?: Callback<TState>) => {
        if (cb) {
            cbRef.current = cb; // store passed callback to ref
        }

        setState(state);
    };

    React.useEffect(() => {
        // cb.current is `null` on initial render, so we only execute cb on state *updates*
        if (cbRef.current) {
            cbRef.current(state);
            cbRef.current = null; // reset callback after execution
        }
    }, [state]);

    return [state, setStateCallback];
}

type DeleteCallback = (toast: _ToastData) => void;
function SelfHidingToast({ toast, onDelete }: { toast: _ToastData; onDelete: DeleteCallback; }) {
    const [open, setOpen] = useStateCallback<boolean>(true);

    React.useEffect(() => {
        setTimeout(() => {
            hide();
        }, 2500);
    });

    const hide = () => {
        setOpen(false, () => {
            setTimeout(() => {
                onDelete(toast)
            }, 500);
        });
    };

    return (
        <RSToast
            isOpen={open}
            style={{ minWidth: 300 }}
        >
            <ToastHeader icon={toast.color} toggle={() => hide()}>
                {toast.title}
            </ToastHeader>
            <ToastBody>
                {toast.text}
                {toast.description && <span className="small text-muted float-right">{toast.description}</span>}
            </ToastBody>
        </RSToast>
    );
}

export function ToastUIProvider(props: React.PropsWithChildren<{}>) {
    const [toasts, setToasts] = useStateCallback<_ToastData[]>([]);

    const removeToast = (toast: _ToastData) => {
        let tx = toasts;

        const index = tx.findIndex(x => x.id === toast.id);

        console.log('Index', index);
        console.log('Remove toast', toast, tx);

        setToasts([...tx.slice(0, index), ...tx.slice(index + 1)], (res) => {
            console.log(res);
        });
    };

    return (
        <ToastUIContext.Provider value={{
            push: (toast: ToastData) => {
                let id = toasts.map(x => x.id).sort((a, b) => b - a)[0] + 1;

                if (isNaN(id)) {
                    id = 1;
                }

                setToasts([
                    {
                        id: id,
                        ...toast
                    },
                    ...toasts
                ]);
            }
        }}>
            <div className="toast-container">
                {toasts.map((toast, i) => (
                    <SelfHidingToast
                        key={i}
                        toast={toast}
                        onDelete={(t) => removeToast(t)}
                    />
                ))}
            </div>
            {props.children}
        </ToastUIContext.Provider>
    );
}

export type WithToastUIProps<T = {}> = {
    push: (title: string, text: string, color: string, description?: string) => void;
} & T;

export function withToastUI<TProps extends WithToastUIProps>(WrappedComponent: React.ComponentType<TProps>) {
    // const toastUIContext = React.useContext(ToastUIContext);

    return ({ push, ...rest }: WithToastUIProps<any>) => (
        <ToastUIContext.Consumer>
            {(t) => (
                <WrappedComponent
                    push={(title: string, text: string, color: string, description?: string) => t.push({
                        title: title,
                        text: text,
                        color: color,
                        description: description
                    })}
                    {...rest}
                />
            )}
        </ToastUIContext.Consumer>
    );
}