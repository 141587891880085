import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { Dispatch, bindActionCreators, Action } from 'redux';
import { Button } from 'reactstrap';
import BlockSpinner from '../components/BlockSpinner';
import { withToastUI } from '../components/Toast';
import ImageForm, { ImageFormFields } from '../forms/ImageForm';
import { addImage } from '../services/Image';
import { BasePage, BasePageProps } from './BasePage';
import { ImageType } from '../contract/common/ImageType';
import { uploadFile } from '../services/Upload';
import { UploadFileThumbnailResult } from '../contract/response/UploadFileThumbnailResult';
import { parseEnum } from '../util/Utils';

const mapStateToProps = (state: ApplicationState) => ({
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
    bindActionCreators(
        {
        },
        dispatch
    );

type NewImageProps =
    BasePageProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {

    };

type NewImageState = {
    loading: boolean;
};

class NewImage extends BasePage<NewImageProps, NewImageState> {
    constructor(props: NewImageProps) {
        super(props);

        this.state = {
            loading: false
        };
    }

    componentDidMount() {
        this.setPageTitle('Yeni Görsel İçerik');
    }

    private async handleFormSubmit(data: ImageFormFields) {
        this.setState({
            loading: true
        });

        if (data.file && data.file.length) {
            let folder: string;

            let imageType = parseEnum<ImageType>(data.type as unknown as string);

            switch (imageType) {
                case ImageType.Gallery:
                    folder = "yaylaoglu.com/images/gallery";
                    break;
                case ImageType.Document:
                    folder = "yaylaoglu.com/docs";
                    break;
                case ImageType.EmbedVideo:
                    folder = "yaylaoglu.com/videos";
                    break;
                case ImageType.Client:
                    folder = "yaylaoglu.com/images/misc";
                    break;
                case ImageType.Custom:
                default:
                    folder = "yaylaoglu.com/images";
                    break;
            }

            const fileUploadResponse = await uploadFile({
                file: data.file[0],
                folder: folder
            });

            if (!fileUploadResponse || !fileUploadResponse.success) {
                this.handleError(fileUploadResponse);

                return;
            }

            // Set image url to upload result url
            data.url = fileUploadResponse.data.url;

            // If it was thumbnail result
            let fileUploadThumbnailResult = fileUploadResponse.data as UploadFileThumbnailResult;
            if (fileUploadThumbnailResult.thumbnailUrl) {
                data.downloadUrl = fileUploadThumbnailResult.url;
                data.url = fileUploadThumbnailResult.thumbnailUrl;
            }
        }

        let response = await addImage({
            ...data
        });

        this.setState({
            loading: false
        });

        if (response && response.success) {
            this.props.push(
                'Başarılı',
                'Görsel içerik eklendi.',
                'success');

            this.redirect((routes) => routes.images.index());
        }
        else {
            this.handleError(response);
        }
    }

    public render() {
        return (
            <>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Yeni Görsel İçerik</h1>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <Button size="sm" outline color="primary">
                            <i className="fa fa-save"></i>{' '}Kaydet
                        </Button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-8">
                        <ImageForm
                            disabled={this.state.loading}
                            initialValues={{
                                type: ImageType.Gallery
                            }}
                            onSubmit={(data) => this.handleFormSubmit(data)}
                        >
                            {this.state.loading && (<BlockSpinner />)}
                        </ImageForm>
                    </div>
                </div>
            </>
        );
    }
}

export default withToastUI(connect(
    mapStateToProps,
    mapDispatchToProps
)(NewImage));