import { DataResponse } from '../contract/Response';
import { Nullable, int } from '../util/Types';
import { UploadFileResult } from '../contract/response/UploadFileResult';
import { UploadFileRequest } from '../contract/request/UploadFileRequest';

export type UploadFileResponse = DataResponse<UploadFileResult>;
export async function uploadFile(
    request: UploadFileRequest
): Promise<Nullable<UploadFileResponse>> {
    var data = new FormData()
    data.append('file', request.file)
    data.append('folder', request.folder);

    let headers = new Headers();
    headers.append('accept', 'application/json');

    let response = await fetch(`/api/uploads`, {
        method: 'POST',
        body: data,
        credentials: 'include',
        headers: headers
    });

    try {
        let responseJson = await response.json();

        return responseJson as UploadFileResponse;
    }
    catch (err) {
    }

    return null;
}