import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as CategoryStore from '../store/Category';
import { Dispatch, bindActionCreators } from 'redux';
import { Table, Button, ButtonGroup } from 'reactstrap';
import BlockSpinner from '../components/BlockSpinner';
import { withToastUI } from '../components/Toast';
import { Link } from 'react-router-dom';
import { Routes } from '../Routes';
import { BasePage, BasePageProps } from './BasePage';
import Prompt from '../components/Prompt';
import { DeleteCategoryResponse, deleteCategory } from '../services/Category';
import { Nullable, int } from '../util/Types';

const mapStateToProps = (state: ApplicationState) => ({
    ...state.category
});

const mapDispatchToProps = (dispatch: Dispatch<CategoryStore.KnownAction>) =>
    bindActionCreators(
        {
            ...CategoryStore.actionCreators
        },
        dispatch
    );

type CategoriesProps =
    BasePageProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {

    };

type CategoriesState = {
    categoryToDelete: Nullable<int>;
    deleting: boolean;
    deleteResponse: Nullable<DeleteCategoryResponse>;
};

class Categories extends BasePage<CategoriesProps, CategoriesState> {
    constructor(props: CategoriesProps) {
        super(props);

        this.state = {
            categoryToDelete: null,
            deleting: false,
            deleteResponse: null
        };
    }

    componentDidMount() {
        this.setPageTitle('Kategoriler');
        
        if (this.props.categories.length <= 0) {
            this.props.requestCategories();
        }
    }

    componentDidUpdate(prevProps: CategoriesProps) {
        // TODO: Handle categories response (error)
        // console.log(prevProps);
    }

    async deleteCategory() {
        if (!this.state.categoryToDelete) {
            return;
        }

        this.setState({
            deleting: true,
            deleteResponse: null
        });

        let response = await deleteCategory(this.state.categoryToDelete);

        this.setState({
            deleting: false,
            deleteResponse: response,
            categoryToDelete: null
        });

        if (response && response.success) {
            this.props.push(
                'Başarılı',
                'Kategori silindi.',
                'success');

            this.props.requestCategories();
        }
        else {
            this.handleError(response);
        }
    }

    public render() {
        return (
            <>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Kategoriler</h1>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <Link to={Routes.categories.new()} className="btn btn-outline-primary btn-sm">
                            <i className="fa fa-plus mr-2"></i>Oluştur
                        </Link>
                    </div>
                </div>

                <div className="table-responsive">
                    <Table responsive striped>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Üst Kategori</th>
                                <th>Ad</th>
                                <th>Uri</th>
                                <th>Açıklama</th>
                                <th>İşlem</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!this.props.loading && this.props.categories.map((category, i) => (
                                <tr key={i}>
                                    <th scope="row">{category.id}</th>
                                    <td>{category.parent ? category.parent.name : ''}</td>
                                    <td>{category.name}</td>
                                    <td>{category.uri}</td>
                                    <td>{category.description}</td>
                                    <td>
                                        <ButtonGroup>
                                            <Link to={Routes.categories.edit(category.id)} className="btn btn-sm btn-info"><i className="fa fa-edit"></i></Link>
                                            <Button size="sm" color="danger" onClick={() => this.setState({ categoryToDelete: category.id })}><i className="fa fa-trash"></i></Button>
                                        </ButtonGroup>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>

                {this.props.loading && (<BlockSpinner />)}

                <Prompt
                    title="Kategori Sil"
                    text="Kategori silinecek, bu işlem geri alınamaz. Emin misiniz?"
                    isOpen={this.state.categoryToDelete !== null}
                    onClose={() => this.setState({ categoryToDelete: null })}
                    onSubmit={async () => await this.deleteCategory()}
                />
            </>
        );
    }
}

export default withToastUI(connect(
    mapStateToProps,
    mapDispatchToProps
)(Categories));