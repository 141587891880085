import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as CategoryStore from '../store/Category';
import { Dispatch, bindActionCreators, Action } from 'redux';
import { Button } from 'reactstrap';
import BlockSpinner from '../components/BlockSpinner';
import { withToastUI } from '../components/Toast';
import CategoryForm, { CategoryFormFields } from '../forms/CategoryForm';
import { AddCategoryResponse, addCategory, getCategoryById, GetCategoryByIdResponse, UpdateCategoryResponse, updateCategory } from '../services/Category';
import { Nullable } from '../util/Types';
import { BasePage, BasePageProps } from './BasePage';
import { Category } from '../contract/dto/Category';
import { RouteComponentProps } from 'react-router';
import { Image } from '../contract/dto/Image';
import { getImageById, updateImage } from '../services/Image';
import ImageForm, { ImageFormFields } from '../forms/ImageForm';
import { parseEnum } from '../util/Utils';
import { ImageType } from '../contract/common/ImageType';
import { uploadFile } from '../services/Upload';
import { UploadFileThumbnailResult } from '../contract/response/UploadFileThumbnailResult';

const mapStateToProps = (state: ApplicationState) => ({
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
    bindActionCreators(
        {
        },
        dispatch
    );

type EditImageProps =
    RouteComponentProps<{ id: string; }> &
    BasePageProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {

    };

type EditImageState = {
    loadingImage: boolean;
    image: Nullable<Image>;
    loading: boolean;
};

class EditImage extends BasePage<EditImageProps, EditImageState> {
    constructor(props: EditImageProps) {
        super(props);

        this.state = {
            loadingImage: false,
            image: null,
            loading: false
        };
    }

    async componentDidMount() {
        this.setPageTitle('Görsel İçerik Düzenle');
        
        await this.fetchData();
    }

    private getId() {
        let id = parseInt(this.props.match.params.id);

        if (isNaN(id)) {
            return null;
        }

        return id;
    }

    private async fetchData() {
        let id = this.getId();
        if (!id) {
            return;
        }

        this.setState({
            loadingImage: true,
            image: null
        });

        let response = await getImageById(id);

        this.setState({
            loadingImage: false
        });

        if (response && response.success) {
            this.setState({
                image: response.data
            });
        }
        else {
            this.handleError(response);
        }
    }

    private async handleFormSubmit(data: ImageFormFields) {
        let id = this.getId();
        if (!id) {
            return;
        }

        this.setState({
            loading: true
        });

        if (data.file && data.file.length) {
            let folder: string;

            let imageType = parseEnum<ImageType>(data.type as unknown as string);

            switch (imageType) {
                case ImageType.Gallery:
                    folder = "yaylaoglu.com/images/gallery";
                    break;
                case ImageType.Document:
                    folder = "yaylaoglu.com/docs";
                    break;
                case ImageType.EmbedVideo:
                    folder = "yaylaoglu.com/videos";
                    break;
                case ImageType.Client:
                    folder = "yaylaoglu.com/images/misc";
                    break;
                case ImageType.Custom:
                default:
                    folder = "yaylaoglu.com/images";
                    break;
            }

            const fileUploadResponse = await uploadFile({
                file: data.file[0],
                folder: folder
            });

            if (!fileUploadResponse || !fileUploadResponse.success) {
                this.handleError(fileUploadResponse);

                return;
            }

            // Set image url to upload result url
            data.url = fileUploadResponse.data.url;

            // If it was thumbnail result
            let fileUploadThumbnailResult = fileUploadResponse.data as UploadFileThumbnailResult;
            if (fileUploadThumbnailResult.thumbnailUrl) {
                data.downloadUrl = fileUploadThumbnailResult.url;
                data.url = fileUploadThumbnailResult.thumbnailUrl;
            }
        }

        let response = await updateImage(
            id,
            {
                ...data
            });

        this.setState({
            loading: false
        });

        if (response && response.success) {
            this.props.push(
                'Başarılı',
                'Görsel içerik güncellendi.',
                'success');

            this.redirect((routes) => routes.images.index());
        }
        else {
            this.handleError(response);
        }
    }

    mapImageToFormValues(): Partial<ImageFormFields> | undefined {
        if (this.state.loadingImage ||
            !this.state.image) {
            return undefined;
        }
        const { image } = this.state;
        return {
            description: image.description,
            downloadUrl: image.downloadUrl,
            type: image.type,
            url: image.url
        }
    }

    public render() {
        return (
            <>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Görsel İçerik Düzenle</h1>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <Button size="sm" outline color="primary">
                            <i className="fa fa-save"></i>{' '}Kaydet
                        </Button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-8">
                        <ImageForm
                            disabled={this.state.loadingImage || this.state.loading || !this.state.image}
                            enableReinitialize
                            initialValues={this.mapImageToFormValues()}
                            onSubmit={(data) => this.handleFormSubmit(data)}
                        >
                            {(this.state.loading || this.state.loadingImage) && (<BlockSpinner />)}
                        </ImageForm>
                    </div>
                </div>
            </>
        );
    }
}

export default withToastUI(connect(
    mapStateToProps,
    mapDispatchToProps
)(EditImage));