import React from 'react';
import { Pagination as RSPagination, PaginationItem, PaginationLink, PaginationLinkProps } from 'reactstrap';
import { PaginatedListRequest } from '../contract/request/PaginatedListRequest';
import { int } from '../util/Types';
import { Link } from 'react-router-dom';

type PaginationProps = PaginatedListRequest & {
    className?: string;
    totalCount: int;
    urlBuilder: (pageIndex: int, pageLength: int) => string;
};

function Pagination(props: PaginationProps) {
    const totalPages = Math.ceil(props.totalCount / props.pageLength);

    return (
        <RSPagination className={"d-inline-flex " + (props.className ? props.className : undefined)}>
            <PaginationItem disabled={props.pageIndex <= 0}>
                <Link to={props.urlBuilder(0, props.pageLength)} className="page-link">
                    <i className="fa fa-angle-double-left"></i>
                </Link>
            </PaginationItem>
            <PaginationItem disabled={props.pageIndex <= 0}>
                <Link to={props.urlBuilder(props.pageIndex - 1, props.pageLength)} className="page-link">
                    <i className="fa fa-chevron-left"></i>
                </Link>
            </PaginationItem>
            {[-1, 0, 1].map((i, index) => {
                let pi = props.pageIndex + i;

                if (pi < 0 ||
                    pi >= totalPages) {
                    return null;
                }

                return (
                    <PaginationItem key={index} active={i == 0}>
                        <Link to={props.urlBuilder(props.pageIndex + i, props.pageLength)} className="page-link">
                            {pi + 1}
                        </Link>
                    </PaginationItem>
                )
            })}

            <PaginationItem disabled={props.pageIndex >= totalPages - 1}>
                <Link to={props.urlBuilder(props.pageIndex + 1, props.pageLength)} className="page-link">
                    <i className="fa fa-chevron-right"></i>
                </Link>
            </PaginationItem>
            <PaginationItem disabled={props.pageIndex >= totalPages - 1}>
                <Link to={props.urlBuilder(totalPages - 1, props.pageLength)} className="page-link">
                    <i className="fa fa-angle-double-right"></i>
                </Link>
            </PaginationItem>
        </RSPagination>
    );
}

export default Pagination;