import { fetchJson } from './Base';
import { PaginatedListResponse, DataResponse, Response } from '../contract/Response';
import { Nullable, int } from '../util/Types';
import { NewsletterSubscription } from '../contract/dto/NewsletterSubscription';
import queryString from 'query-string';

export type GetNewsletterSubscriptionsResponse = PaginatedListResponse<NewsletterSubscription>;
export async function getNewsletterSubscriptions(
    pageIndex: int = 0,
    pageLength: int = 50
): Promise<Nullable<GetNewsletterSubscriptionsResponse>> {
    let query = queryString.stringify({
        pageIndex: pageIndex,
        pageLength: pageLength
    });

    return await fetchJson<GetNewsletterSubscriptionsResponse>(`/api/newsletter-subscriptions?${query}`, {
        method: 'GET'
    });
}

export type GetNewsletterSubscriptionByIdResponse = DataResponse<NewsletterSubscription>;
export async function getNewsletterSubscriptionById(id: int): Promise<Nullable<GetNewsletterSubscriptionByIdResponse>> {
    return await fetchJson<GetNewsletterSubscriptionByIdResponse>(`/api/newsletter-subscriptions/${id}`, {
        method: 'GET'
    });
}

export type DeleteNewsletterSubscriptionResponse = Response;
export async function deleteNewsletterSubscription(
    id: int
): Promise<Nullable<DeleteNewsletterSubscriptionResponse>> {
    return await fetchJson<DeleteNewsletterSubscriptionResponse>(`/api/newsletter-subscriptions/${id}`, {
        method: 'DELETE'
    });
}