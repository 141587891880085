import { fetchJson } from './Base';
import { PaginatedListResponse } from '../contract/Response';
import { Nullable, int } from '../util/Types';
import { PageVisit } from '../contract/dto/PageVisit';
import queryString from 'query-string';

export type GetPageVisitsResponse = PaginatedListResponse<PageVisit>;
export async function getPageVisits(
    pageIndex: int = 0,
    pageLength: int = 50
): Promise<Nullable<GetPageVisitsResponse>> {
    let query = queryString.stringify({
        pageIndex: pageIndex,
        pageLength: pageLength
    });

    return await fetchJson<GetPageVisitsResponse>(`/api/page-visits?${query}`, {
        method: 'GET'
    });
}