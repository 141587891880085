export const required = (value: any) => (value || typeof value === 'number' ? undefined : 'Gerekli');

export const requiredFile = (value: FileList) => ((value && value.length >= 1) ? undefined : 'Gerekli');

export const fileFormat = (value: FileList) => ((!value || value.length <= 0 || allowedMimeList.indexOf(value[0].type) !== -1) ? undefined : 'Geçersiz dosya tipi');

const allowedMimeList = [
    'image/jpeg',
    'image/png',
    'application/pdf'
];

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

const phoneNumberRegex = /^(\+?[0-9]{2}|[0-9]{3})? ?\(?5[0-9]{2}\)? ?([0-9]{3}) ?([0-9]{4})$/i;

const tcknRegex = /^[1-9]{1}[0-9]{9}[02468]{1}$/i;

const uriRegex = /^([A-Za-z0-9-])*$/i;

export const email = (value: any) =>
    (value && !emailRegex.test(value)
        ? 'Geçersiz e-posta'
        : undefined);

export const phoneNumber = (value: any) =>
    (value && !phoneNumberRegex.test(value)
        ? 'Geçersiz telefon numarası'
        : undefined);

export const tckn = (value: any) =>
    (value && !tcknRegex.test(value)
        ? 'Geçersiz T.C. kimlik numarası'
        : undefined);

export const uri = (value: any) =>
    (value && !uriRegex.test(value)
        ? 'Geçersiz URI'
        : undefined);

export const emailOrPhoneNumber = (value: any) =>
    (value && !emailRegex.test(value) && !phoneNumberRegex.test(value))
        ? 'Geçersiz kullanıcı adı'
        : undefined;

export const tooManyPizzas = (value: any) => (value > 15 ? 'Are you mad?' : undefined);

export const password = (value: any) => undefined;

export const maxLength = (max: number) => (value: any) =>
    value && value.length > max ? `En fazla ${max} karakter olmalıdır` : undefined;

export const minLength = (min: number) => (value: any) =>
    value && value.length < min ? `En az ${min} karakter olmalıdır` : undefined;

export const minLength5 = minLength(5);

export const number = (value: any) =>
    value && isNaN(Number(value)) ? 'Sayı olmalıdır' : undefined;

// Domain rules

export const usernameRules = [required, emailOrPhoneNumber];

export const passwordRules = [required, minLength(5)];

export const matches = (value: any, allValues: any) =>
    value !== allValues.password
        ? "Parolalar eşleşmeli"
        : undefined;