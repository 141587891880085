import * as React from 'react';
import { Switch } from 'react-router';
import Layout from './components/Layout';
import SignIn from './pages/SignIn';
import Home from './pages/Home';
import Categories from './pages/Categories';
import NewCategory from './pages/NewCategory';
import EditCategory from './pages/EditCategory';
import Products from './pages/Products';
import NewProduct from './pages/NewProduct';
import EditProduct from './pages/EditProduct';
import Properties from './pages/Properties';
import NewProperty from './pages/NewProperty';
import EditProperty from './pages/EditProperty';
import Images from './pages/Images';
import NewImage from './pages/NewImage';
import EditImage from './pages/EditImage';
import Posts from './pages/Posts';
import NewPost from './pages/NewPost';
import EditPost from './pages/EditPost';
import Slides from './pages/Slides';
import NewSlide from './pages/NewSlide';
import EditSlide from './pages/EditSlide';
import ContactForms from './pages/ContactForms';
import NewsletterSubscriptions from './pages/NewsletterSubscriptions';
import PageVisits from './pages/PageVisits';
import PrivateRoute from './components/PrivateRoute';
import Loading from './components/Loading';
import { RouteSchemes } from './Routes';
import { connect } from 'react-redux';
import { ApplicationState } from './store';
import * as SystemStore from './store/System';
import { Dispatch, bindActionCreators } from 'redux';
import { ToastUIProvider } from './components/Toast';

// Style
import './custom.css';


const mapStateToProps = (state: ApplicationState) => ({
    ...state.system
});

const mapDispatchToProps = (dispatch: Dispatch<SystemStore.KnownAction>) =>
    bindActionCreators(
        {
            ...SystemStore.actionCreators
        },
        dispatch
    );

type AppProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

type AppState = {
    loading: boolean;
};

class App extends React.PureComponent<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);

        this.state = {
            loading: true
        };
    }

    public async componentDidMount() {
        if (!this.props.user) {
            this.props.requestUser();

            this.setState({
                loading: false
            });
        }
    }

    public render() {
        if (this.props.loadingUser ||
            this.state.loading) {
            return <Loading />;
        }

        return (
            <ToastUIProvider>
                <Switch>
                    <PrivateRoute exact path={RouteSchemes.account.signIn} component={SignIn} />
                    <PrivateRoute exact path={RouteSchemes.home} layout={Layout} component={Home} isPrivate />
                    <PrivateRoute exact path={RouteSchemes.categories.index} layout={Layout} component={Categories} isPrivate />
                    <PrivateRoute exact path={RouteSchemes.categories.new} layout={Layout} component={NewCategory} isPrivate />
                    <PrivateRoute exact path={RouteSchemes.categories.edit} layout={Layout} component={EditCategory} isPrivate />
                    <PrivateRoute exact path={RouteSchemes.products.index} layout={Layout} component={Products} isPrivate />
                    <PrivateRoute exact path={RouteSchemes.products.new} layout={Layout} component={NewProduct} isPrivate />
                    <PrivateRoute exact path={RouteSchemes.products.edit} layout={Layout} component={EditProduct} isPrivate />
                    <PrivateRoute exact path={RouteSchemes.properties.index} layout={Layout} component={Properties} isPrivate />
                    <PrivateRoute exact path={RouteSchemes.properties.new} layout={Layout} component={NewProperty} isPrivate />
                    <PrivateRoute exact path={RouteSchemes.properties.edit} layout={Layout} component={EditProperty} isPrivate />
                    <PrivateRoute exact path={RouteSchemes.images.index} layout={Layout} component={Images} isPrivate />
                    <PrivateRoute exact path={RouteSchemes.images.new} layout={Layout} component={NewImage} isPrivate />
                    <PrivateRoute exact path={RouteSchemes.images.edit} layout={Layout} component={EditImage} isPrivate />
                    <PrivateRoute exact path={RouteSchemes.posts.index} layout={Layout} component={Posts} isPrivate />
                    <PrivateRoute exact path={RouteSchemes.posts.new} layout={Layout} component={NewPost} isPrivate />
                    <PrivateRoute exact path={RouteSchemes.posts.edit} layout={Layout} component={EditPost} isPrivate />
                    <PrivateRoute exact path={RouteSchemes.slides.index} layout={Layout} component={Slides} isPrivate />
                    <PrivateRoute exact path={RouteSchemes.slides.new} layout={Layout} component={NewSlide} isPrivate />
                    <PrivateRoute exact path={RouteSchemes.slides.edit} layout={Layout} component={EditSlide} isPrivate />
                    <PrivateRoute exact path={RouteSchemes.contactForms.index} layout={Layout} component={ContactForms} isPrivate />
                    <PrivateRoute exact path={RouteSchemes.newsletterSubscriptions.index} layout={Layout} component={NewsletterSubscriptions} isPrivate />
                    <PrivateRoute exact path={RouteSchemes.pageVisits.index} layout={Layout} component={PageVisits} isPrivate />
                </Switch>
            </ToastUIProvider>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);