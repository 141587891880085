import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as CategoryStore from '../store/Category';
import { Dispatch, bindActionCreators } from 'redux';
import { Table, Button, ButtonGroup, Row, Col, Card, CardTitle, CardText } from 'reactstrap';
import BlockSpinner from '../components/BlockSpinner';
import { withToastUI } from '../components/Toast';
import { Link } from 'react-router-dom';
import { Routes } from '../Routes';
import { BasePage, BasePageProps } from './BasePage';
import Prompt from '../components/Prompt';
import { Nullable, int } from '../util/Types';
import { Product } from '../contract/dto/Product';
import { deleteProduct, getProductsByCategory, GetProductsByCategoryResponse, DeleteProductResponse } from '../services/Product';
import ProductFilterForm, { ProductFilterFormFields, ProductFilterFormName } from '../forms/ProductFilterForm';
import { FormAction, submit } from 'redux-form';

const mapStateToProps = (state: ApplicationState) => ({
    ...state.category
});

const mapDispatchToProps = (dispatch: Dispatch<CategoryStore.KnownAction>) =>
    bindActionCreators(
        {
            ...CategoryStore.actionCreators,
            submitForm: (form: string) => dispatch(submit(form))
        },
        dispatch
    );

type ProductsProps =
    BasePageProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {

    };

type ProductsState = {
    loadingProducts: boolean;
    products: Nullable<Product[]>;
    productsResponse: Nullable<GetProductsByCategoryResponse>;
    productToDelete: Nullable<int>;
    deleting: boolean;
    deleteResponse: Nullable<DeleteProductResponse>;
};

class Products extends BasePage<ProductsProps, ProductsState> {
    constructor(props: ProductsProps) {
        super(props);

        this.state = {
            loadingProducts: false,
            products: null,
            productsResponse: null,
            productToDelete: null,
            deleting: false,
            deleteResponse: null
        };
    }

    componentDidMount() {
        this.setPageTitle('Ürünler');

        if (this.props.categories.length <= 0) {
            this.props.requestCategories();
        }
    }

    componentDidUpdate(prevProps: ProductsProps) {
        if (this.props.categories && this.props.categories.length && (this.state.products == null || !this.state.products.length)) {
            this.props.submitForm(ProductFilterFormName);
        }
        // TODO: Handle categories response (error)
        // console.log(prevProps);
    }

    private async handleFormSubmit(data: ProductFilterFormFields) {
        this.setState({
            loadingProducts: true,
            products: null,
            productsResponse: null
        });

        let response = await getProductsByCategory(data.category);

        this.setState({
            loadingProducts: false,
            productsResponse: response
        });

        if (response && response.success) {
            this.setState({
                products: response.data
            });
        }
        else {
            this.handleError(response);
        }
    }

    async deleteProduct() {
        if (!this.state.productToDelete) {
            return;
        }

        this.setState({
            deleting: true,
            deleteResponse: null
        });

        let response = await deleteProduct(this.state.productToDelete);

        this.setState({
            deleting: false,
            deleteResponse: response,
            productToDelete: null
        });

        if (response && response.success) {
            this.props.push(
                'Başarılı',
                'Ürün silindi.',
                'success');

            this.props.submitForm(ProductFilterFormName);
        }
        else {
            this.handleError(response);
        }
    }

    public render() {
        return (
            <>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Ürünler</h1>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <Link to={Routes.products.new()} className="btn btn-outline-primary btn-sm">
                            <i className="fa fa-plus mr-2"></i>Oluştur
                        </Link>
                    </div>
                </div>

                <Row>
                    <Col sm="12">
                        <ProductFilterForm
                            categories={this.props.categories}
                            onSubmit={(data) => this.handleFormSubmit(data)}
                            disabled={this.state.loadingProducts}
                            enableReinitialize
                            initialValues={{
                                category: this.props.categories && this.props.categories.length && this.props.categories[0].id
                            }}
                        >
                            {this.state.loadingProducts && (<BlockSpinner />)}
                        </ProductFilterForm>
                    </Col>
                </Row>

                <div className="table-responsive">
                    <Table responsive striped>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Ad</th>
                                <th>Uri</th>
                                <th>Açıklama</th>
                                <th>İşlem</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!this.state.loadingProducts && this.state.products && this.state.products.map((product, i) => (
                                <tr key={i}>
                                    <th scope="row">{product.id}</th>
                                    <td>{product.name}</td>
                                    <td>{product.uri}</td>
                                    <td>{product.description}</td>
                                    <td>
                                        <ButtonGroup>
                                            <Link to={Routes.products.edit(product.id)} className="btn btn-sm btn-info"><i className="fa fa-edit"></i></Link>
                                            <Button size="sm" color="danger" onClick={() => this.setState({ productToDelete: product.id })}><i className="fa fa-trash"></i></Button>
                                        </ButtonGroup>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>

                {this.props.loading && (<BlockSpinner />)}

                <Prompt
                    title="Ürün Sil"
                    text="Ürün silinecek, bu işlem geri alınamaz. Emin misiniz?"
                    isOpen={this.state.productToDelete !== null}
                    onClose={() => this.setState({ productToDelete: null })}
                    onSubmit={async () => await this.deleteProduct()}
                />
            </>
        );
    }
}

export default withToastUI(connect(
    mapStateToProps,
    mapDispatchToProps
)(Products));