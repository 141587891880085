import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { Dispatch, bindActionCreators, Action } from 'redux';
import { Table, Button, ButtonGroup, Col, Row, Label } from 'reactstrap';
import BlockSpinner from '../components/BlockSpinner';
import { withToastUI } from '../components/Toast';
import { Routes } from '../Routes';
import { BasePage, BasePageProps } from './BasePage';
import Prompt from '../components/Prompt';
import Dialog from '../components/Dialog';
import { Nullable, int } from '../util/Types';
import { parseQueryString } from '../util/Utils';
import { dateTimeText, newsletterSubscriptionStatusText } from '../util/TextUtilities';
import { PaginatedListRequest } from '../contract/request/PaginatedListRequest';
import Pagination from '../components/Pagination';
import { NewsletterSubscription } from '../contract/dto/NewsletterSubscription';
import { getNewsletterSubscriptions, deleteNewsletterSubscription } from '../services/NewsletterSubscription';

const mapStateToProps = (state: ApplicationState) => ({
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
    bindActionCreators(
        {
        },
        dispatch
    );

const LabelText = (props: { label?: string; text?: string; }) => (
    <Row>
        <Col md={3} className="text-right">
            <Label className="text-muted">{props.label}</Label>
        </Col>
        <Col md={9}>
            <p>
                {props.text}
            </p>
        </Col>
    </Row>
);

type NewsletterSubscriptionsProps =
    BasePageProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {

    };

type NewsletterSubscriptionsState = {
    loadingNewsletterSubscriptions: boolean;
    pageIndex: int;
    pageLength: int;
    newsletterSubscriptions: Nullable<NewsletterSubscription[]>;
    totalCount: int;
    newsletterSubscriptionToDelete: Nullable<int>;
    newsletterSubscriptionToShow: Nullable<NewsletterSubscription>;
    deleting: boolean;
};

class NewsletterSubscriptions extends BasePage<NewsletterSubscriptionsProps, NewsletterSubscriptionsState> {
    constructor(props: NewsletterSubscriptionsProps) {
        super(props);

        this.state = {
            loadingNewsletterSubscriptions: false,
            pageIndex: -1,
            pageLength: -1,
            newsletterSubscriptions: [],
            totalCount: 0,
            newsletterSubscriptionToDelete: null,
            newsletterSubscriptionToShow: null,
            deleting: false
        };
    }

    async componentDidMount() {
        this.setPageTitle('Haber Bülten Kayıtları');
        
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: NewsletterSubscriptionsProps) {
        let previousPagination = parseQueryString<PaginatedListRequest>(prevProps.location.search);
        let currentPagination = this.getPaginationParameters();

        if (previousPagination.pageIndex !== currentPagination.pageIndex ||
            previousPagination.pageLength !== currentPagination.pageLength) {
            await this.fetchData();
        }
    }

    private getPaginationParameters(): PaginatedListRequest {
        let paginationParameters = parseQueryString<PaginatedListRequest>(this.props.location.search);

        return {
            pageIndex: paginationParameters.pageIndex || 0,
            pageLength: paginationParameters.pageLength || 50
        };
    }

    private async fetchData() {
        let paginationParameters = this.getPaginationParameters();

        if (this.state.pageIndex === paginationParameters.pageIndex &&
            this.state.pageLength === paginationParameters.pageLength) {
            return;
        }

        this.setState({
            loadingNewsletterSubscriptions: true,
            newsletterSubscriptions: null,
            totalCount: 0,
            pageIndex: paginationParameters.pageIndex,
            pageLength: paginationParameters.pageLength
        });

        let response = await getNewsletterSubscriptions(paginationParameters.pageIndex, paginationParameters.pageLength);

        this.setState({
            loadingNewsletterSubscriptions: false
        });

        if (response && response.success) {
            this.setState({
                newsletterSubscriptions: response.data,
                totalCount: response.totalCount
            });
        }
        else {
            this.handleError(response);
        }
    }

    async deleteNewsletterSubscription() {
        if (!this.state.newsletterSubscriptionToDelete) {
            return;
        }

        this.setState({
            deleting: true
        });

        let response = await deleteNewsletterSubscription(this.state.newsletterSubscriptionToDelete);

        this.setState({
            deleting: false,
            newsletterSubscriptionToDelete: null
        });

        if (response && response.success) {
            this.props.push(
                'Başarılı',
                'Haber bülteni kaydı silindi.',
                'success');

            this.fetchData();
        }
        else {
            this.handleError(response);
        }
    }

    public render() {
        return (
            <>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Haber Bülteni Kayıtları</h1>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <Button className="btn btn-outline-primary btn-sm" disabled>
                            <i className="fa fa-mail-bulk mr-2"></i>Toplu Email Gönder
                        </Button>
                    </div>
                </div>

                <Pagination
                    pageIndex={this.getPaginationParameters().pageIndex}
                    pageLength={this.getPaginationParameters().pageLength}
                    totalCount={this.state.totalCount}
                    urlBuilder={(pi, pl) => Routes.newsletterSubscriptions.index(pi, pl)}
                />

                <div className="table-responsive">
                    <Table responsive striped>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Kayıt Tarihi</th>
                                <th>E-posta</th>
                                <th>Durum</th>
                                <th>İşlem</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(!this.state.loadingNewsletterSubscriptions && this.state.newsletterSubscriptions) && this.state.newsletterSubscriptions.map((newsletterSubscription, i) => (
                                <tr key={i}>
                                    <th scope="row">{newsletterSubscription.id}</th>
                                    <td>{newsletterSubscription.email}</td>
                                    <td>{dateTimeText(newsletterSubscription.addedTime)}</td>
                                    <td>{newsletterSubscriptionStatusText(newsletterSubscription.status)}</td>
                                    <td>
                                        <ButtonGroup>
                                            <Button size="sm" color="info" onClick={() => this.setState({ newsletterSubscriptionToShow: newsletterSubscription })}><i className="fa fa-edit"></i></Button>
                                            <Button size="sm" color="danger" onClick={() => this.setState({ newsletterSubscriptionToDelete: newsletterSubscription.id })}><i className="fa fa-trash"></i></Button>
                                        </ButtonGroup>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>

                <Pagination
                    pageIndex={this.getPaginationParameters().pageIndex}
                    pageLength={this.getPaginationParameters().pageLength}
                    totalCount={this.state.totalCount}
                    urlBuilder={(pi, pl) => Routes.newsletterSubscriptions.index(pi, pl)}
                />

                {(this.state.loadingNewsletterSubscriptions || this.state.deleting) && (<BlockSpinner />)}

                <Prompt
                    title="Haber Bülten Kaydı Sil"
                    text="Haber bülteni kaydı silinecek, bu işlem geri alınamaz. Emin misiniz?"
                    isOpen={this.state.newsletterSubscriptionToDelete !== null}
                    onClose={() => this.setState({ newsletterSubscriptionToDelete: null })}
                    onSubmit={async () => await this.deleteNewsletterSubscription()}
                />

                <Dialog
                    title="Haber Bülteni Kaydı"
                    isOpen={this.state.newsletterSubscriptionToShow !== null}
                    onClose={() => this.setState({ newsletterSubscriptionToShow: null })}
                >
                    {this.state.newsletterSubscriptionToShow && (
                        <>
                            <LabelText label="E-posta adresi" text={this.state.newsletterSubscriptionToShow.email} />
                            <LabelText label="Eklenme tarihi" text={dateTimeText(this.state.newsletterSubscriptionToShow.addedTime)} />
                            <LabelText label="Durum" text={newsletterSubscriptionStatusText(this.state.newsletterSubscriptionToShow.status)} />
                        </>
                    )}
                </Dialog>
            </>
        );
    }
}

export default withToastUI(connect(
    mapStateToProps,
    mapDispatchToProps
)(NewsletterSubscriptions));