import { fetchJson } from './Base';
import { AddImageRequest } from '../contract/request/AddImageRequest';
import { UpdateImageRequest } from '../contract/request/UpdateImageRequest';
import { ListResponse, DataResponse, Response } from '../contract/Response';
import { Nullable, int } from '../util/Types';
import { Image } from '../contract/dto/Image';
import { ImageType } from '../contract/common/ImageType';

export type GetImagesResponse = ListResponse<Image>;
export async function getImages(imageType: ImageType): Promise<Nullable<GetImagesResponse>> {
    return await fetchJson<GetImagesResponse>(`/api/images?imageType=${imageType}`, {
        method: 'GET'
    });
}

export type AddImageResponse = DataResponse<Image>;
export async function addImage(
    request: AddImageRequest
): Promise<Nullable<AddImageResponse>> {
    return await fetchJson<AddImageResponse>(`/api/images`, {
        method: 'POST',
        body: JSON.stringify(request)
    });
}

export type GetImageByIdResponse = DataResponse<Image>;
export async function getImageById(id: int): Promise<Nullable<GetImageByIdResponse>> {
    return await fetchJson<GetImageByIdResponse>(`/api/images/${id}`, {
        method: 'GET'
    });
}

export type UpdateImageResponse = DataResponse<Image>;
export async function updateImage(
    id: int,
    request: UpdateImageRequest
): Promise<Nullable<UpdateImageResponse>> {
    return await fetchJson<UpdateImageResponse>(`/api/images/${id}`, {
        method: 'PUT',
        body: JSON.stringify(request)
    });
}

export type DeleteImageResponse = Response;
export async function deleteImage(
    id: int
): Promise<Nullable<DeleteImageResponse>> {
    return await fetchJson<DeleteImageResponse>(`/api/images/${id}`, {
        method: 'DELETE'
    });
}