import { fetchJson } from './Base';
import { AddSlideRequest } from '../contract/request/AddSlideRequest';
import { UpdateSlideRequest } from '../contract/request/UpdateSlideRequest';
import { ListResponse, DataResponse, Response } from '../contract/Response';
import { Nullable, int } from '../util/Types';
import { Slide } from '../contract/dto/Slide';

export type GetSlidesResponse = ListResponse<Slide>;
export async function getSlides(): Promise<Nullable<GetSlidesResponse>> {
    return await fetchJson<GetSlidesResponse>(`/api/slides`, {
        method: 'GET'
    });
}

export type AddSlideResponse = DataResponse<Slide>;
export async function addSlide(
    request: AddSlideRequest
): Promise<Nullable<AddSlideResponse>> {
    return await fetchJson<AddSlideResponse>(`/api/slides`, {
        method: 'POST',
        body: JSON.stringify(request)
    });
}

export type GetSlideByIdResponse = DataResponse<Slide>;
export async function getSlideById(id: int): Promise<Nullable<GetSlideByIdResponse>> {
    return await fetchJson<GetSlideByIdResponse>(`/api/slides/${id}`, {
        method: 'GET'
    });
}

export type UpdateSlideResponse = DataResponse<Slide>;
export async function updateSlide(
    id: int,
    request: UpdateSlideRequest
): Promise<Nullable<UpdateSlideResponse>> {
    return await fetchJson<UpdateSlideResponse>(`/api/slides/${id}`, {
        method: 'PUT',
        body: JSON.stringify(request)
    });
}

export type DeleteSlideResponse = Response;
export async function deleteSlide(
    id: int
): Promise<Nullable<DeleteSlideResponse>> {
    return await fetchJson<DeleteSlideResponse>(`/api/slides/${id}`, {
        method: 'DELETE'
    });
}