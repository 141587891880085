import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { Dispatch, bindActionCreators, Action } from 'redux';
import { Button } from 'reactstrap';
import BlockSpinner from '../components/BlockSpinner';
import { withToastUI } from '../components/Toast';
import { Nullable } from '../util/Types';
import { BasePage, BasePageProps } from './BasePage';
import { RouteComponentProps } from 'react-router';
import { getPostById, updatePost } from '../services/Post';
import { Post } from '../contract/dto/Post';
import PostForm, { PostFormFields } from '../forms/PostForm';

const mapStateToProps = (state: ApplicationState) => ({
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
    bindActionCreators(
        {
        },
        dispatch
    );

type EditPostProps =
    RouteComponentProps<{ id: string; }> &
    BasePageProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {

    };

type EditPostState = {
    loadingPost: boolean;
    post: Nullable<Post>;
    loading: boolean;
};

class EditPost extends BasePage<EditPostProps, EditPostState> {
    constructor(props: EditPostProps) {
        super(props);

        this.state = {
            loadingPost: false,
            post: null,
            loading: false
        };
    }

    async componentDidMount() {
        this.setPageTitle('Makale Düzenle');
        
        await this.fetchData();
    }

    private getId() {
        let id = parseInt(this.props.match.params.id);

        if (isNaN(id)) {
            return null;
        }

        return id;
    }

    private async fetchData() {
        let id = this.getId();
        if (!id) {
            return;
        }

        this.setState({
            loadingPost: true,
            post: null
        });

        let response = await getPostById(id);

        this.setState({
            loadingPost: false
        });

        if (response && response.success) {
            this.setState({
                post: response.data
            });
        }
        else {
            this.handleError(response);
        }
    }

    private async handleFormSubmit(data: PostFormFields) {
        let id = this.getId();
        if (!id) {
            return;
        }

        this.setState({
            loading: true
        });

        let response = await updatePost(
            id,
            {
                ...data
            });

        this.setState({
            loading: false
        });

        if (response && response.success) {
            this.props.push(
                'Başarılı',
                'Makale güncellendi.',
                'success');

            this.redirect((routes) => routes.posts.index());
        }
        else {
            this.handleError(response);
        }
    }

    mapPostToFormValues(): Partial<PostFormFields> | undefined {
        if (this.state.loadingPost ||
            !this.state.post) {
            return undefined;
        }
        const { post } = this.state;
        return {
            bannerImageUrl: post.bannerImageUrl,
            isFeatured: post.isFeatured,
            name: post.name,
            rawContent: post.rawContent,
            summary: post.summary,
            thumbnailImageUrl: post.thumbnailImageUrl,
            uri: post.uri
        }
    }

    public render() {
        return (
            <>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Makale Düzenle</h1>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <Button size="sm" outline color="primary">
                            <i className="fa fa-save"></i>{' '}Kaydet
                        </Button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-8">
                        <PostForm
                            disabled={this.state.loadingPost || this.state.loading || !this.state.post}
                            enableReinitialize
                            initialValues={this.mapPostToFormValues()}
                            onSubmit={(data) => this.handleFormSubmit(data)}
                        >
                            {(this.state.loading || this.state.loadingPost) && (<BlockSpinner />)}
                        </PostForm>
                    </div>
                </div>
            </>
        );
    }
}

export default withToastUI(connect(
    mapStateToProps,
    mapDispatchToProps
)(EditPost));