import * as React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { renderInput, renderTextArea, renderCheckbox } from '../components/FormElements';
import { required, uri } from '../util/Validation';
import { getFieldName } from '../util/Utils';
import { Form, Label, Button, FormGroup } from 'reactstrap';

export type PostFormFields = {
    isFeatured: boolean;
    thumbnailImageUrl: string;
    bannerImageUrl: string;
    uri: string;
    name: string;
    summary: string;
    rawContent: string;
}

type PostFormFieldsProps = {
    prefix?: string;
};

export function PostFormFields(props: PostFormFieldsProps) {
    return (
        <React.Fragment>
            <FormGroup>
                <Label for="name">Ad</Label>
                <Field
                    id="name"
                    name={`${getFieldName<PostFormFields>('name', props.prefix)}`}
                    component={renderInput}
                    label="Ad"
                    validate={[required]}
                />
            </FormGroup>

            <FormGroup>
                <Label for="uri">URI</Label>
                <Field
                    id="uri"
                    name={`${getFieldName<PostFormFields>('uri', props.prefix)}`}
                    component={renderInput}
                    label="URI"
                    validate={[required, uri]}
                />
            </FormGroup>

            <FormGroup>
                <Label for="thumbnailImageUrl">Önizleme Görsel (URL)</Label>
                <Field
                    id="thumbnailImageUrl"
                    name={`${getFieldName<PostFormFields>('thumbnailImageUrl', props.prefix)}`}
                    component={renderInput}
                    label="Önizleme Görsel (URL)"
                    validate={[required]}
                />
            </FormGroup>

            <FormGroup>
                <Label for="bannerImageUrl">Banner Görsel (URL)</Label>
                <Field
                    id="bannerImageUrl"
                    name={`${getFieldName<PostFormFields>('bannerImageUrl', props.prefix)}`}
                    component={renderInput}
                    label="Banner Görsel (URL)"
                    validate={[required]}
                />
            </FormGroup>
            
            <FormGroup>
                <Label for="summary">Özet</Label>
                <Field
                    id="summary"
                    name={`${getFieldName<PostFormFields>('summary', props.prefix)}`}
                    component={renderInput}
                    label="Özet"
                    validate={[required]}
                />
            </FormGroup>

            <FormGroup>
                <Label for="rawContent">İçerik</Label>
                <Field
                    id="rawContent"
                    name={`${getFieldName<PostFormFields>('rawContent', props.prefix)}`}
                    component={renderTextArea}
                    label="İçerik"
                    validate={[required]}
                />
            </FormGroup>
            
            <FormGroup check>
                <Label check>
                    <Field
                        id="isFeatured"
                        name={`${getFieldName<PostFormFields>('isFeatured', props.prefix)}`}
                        component={renderCheckbox}
                        label="Ana Sayfa'da göster"
                        type="checkbox"
                    />{' '}Ana Sayfa'da göster
                    </Label>
            </FormGroup>

        </React.Fragment>
    );
}

type _PostFormProps = PostFormFieldsProps & {
    disabled?: boolean;
};

export type PostFormProps = InjectedFormProps<PostFormFields, _PostFormProps> &
    _PostFormProps;

class PostForm extends React.PureComponent<PostFormProps> {
    public render() {
        const { handleSubmit } = this.props;

        return (
            <Form onSubmit={(s) => handleSubmit(s)} noValidate disabled={this.props.disabled}>
                <PostFormFields prefix={this.props.prefix} />

                <Button type="submit" color="primary"><i className="fa fa-save mr-2"></i>Kaydet</Button>

                {this.props.children}
            </Form>
        );
    }
};

export const PostFormName: string = 'category';

export default reduxForm<PostFormFields, _PostFormProps>({
    form: PostFormName
})(PostForm);