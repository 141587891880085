import * as React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { renderInput, renderTextArea } from '../components/FormElements';
import { required, uri } from '../util/Validation';
import { getFieldName } from '../util/Utils';
import { Form, Label, Button, FormGroup } from 'reactstrap';

export type PropertyFormFields = {
    name: string;
}

type PropertyFormFieldsProps = {
    prefix?: string;
};

export function PropertyFormFields(props: PropertyFormFieldsProps) {
    return (
        <React.Fragment>
            <FormGroup>
                <Label for="name">Ad</Label>
                <Field
                    id="name"
                    name={`${getFieldName<PropertyFormFields>('name', props.prefix)}`}
                    component={renderInput}
                    label="Ad"
                    validate={[required]}
                />
            </FormGroup>
        </React.Fragment>
    );
}

type _PropertyFormProps = PropertyFormFieldsProps & {
    disabled?: boolean;
};

export type PropertyFormProps = InjectedFormProps<PropertyFormFields, _PropertyFormProps> &
    _PropertyFormProps;

class PropertyForm extends React.PureComponent<PropertyFormProps> {
    public render() {
        const { handleSubmit } = this.props;

        return (
            <Form onSubmit={(s) => handleSubmit(s)} noValidate disabled={this.props.disabled}>
                <PropertyFormFields prefix={this.props.prefix} />

                <Button type="submit" color="primary"><i className="fa fa-save mr-2"></i>Kaydet</Button>

                {this.props.children}
            </Form>
        );
    }
};

export const PropertyFormName: string = 'property';

export default reduxForm<PropertyFormFields, _PropertyFormProps>({
    form: PropertyFormName
})(PropertyForm);