import { ImageType } from "../contract/common/ImageType";
import { DateTime } from "./Types";
import { NewsletterSubscriptionStatus } from "../contract/common/NewsletterSubscriptionStatus";
import { STATUS_CODES } from "http";

export function imageTypeText(imageType: ImageType) {
    switch (imageType) {
        case ImageType.Gallery:
            return 'Galeri';
        case ImageType.Document:
            return 'Belge';
        case ImageType.Custom:
            return 'Özel';
        case ImageType.EmbedVideo:
            return 'Video';
        case ImageType.Client:
            return 'Müşteri';
        default:
            break;
    }
}

export function dateTime(dateTime: DateTime): Date {
    if (typeof (dateTime) === "string") {
        return new Date(dateTime);
    }

    return dateTime;
}

export function dateTimeText(dt: DateTime) {
    return dateTime(dt).toLocaleString();
}

export function newsletterSubscriptionStatusText(newsletterSubscriptionStatus: NewsletterSubscriptionStatus) {
    switch (newsletterSubscriptionStatus) {
        case NewsletterSubscriptionStatus.Subscribed:
            return 'Kayıtlı';
        case NewsletterSubscriptionStatus.Canceled:
            return 'İptal';
        default:
            break;
    }
}

export function httpStatusText(statusCode: number | string) {
    statusCode = statusCode.toString();

    switch (statusCode) {
        case '200':
            return 'OK';
        case '400':
            return 'Hatalı İstek';
        case '401':
            return 'Yetki Yok';
        case '404':
            return 'Bulunamadı';
        case '500':
            return 'Sunucu Hatası';
        default:
            return statusCode;
    }
}