import { int } from "./util/Types";
import { withQueryString } from "./util/Utils";

export const Routes = {
    account: {
        signIn: () => RouteSchemes.account.signIn
    },
    home: () => RouteSchemes.home,
    categories: {
        index: () => RouteSchemes.categories.index,
        new: () => RouteSchemes.categories.new,
        edit: (id: int) => buildRoute(RouteSchemes.categories.edit, id)
    },
    products: {
        index: () => RouteSchemes.products.index,
        new: () => RouteSchemes.products.new,
        edit: (id: int) => buildRoute(RouteSchemes.products.edit, id)
    },
    properties: {
        index: () => RouteSchemes.properties.index,
        new: () => RouteSchemes.properties.new,
        edit: (id: int) => buildRoute(RouteSchemes.properties.edit, id)
    },
    images: {
        index: () => RouteSchemes.images.index,
        new: () => RouteSchemes.images.new,
        edit: (id: int) => buildRoute(RouteSchemes.images.edit, id)
    },
    posts: {
        index: () => RouteSchemes.posts.index,
        new: () => RouteSchemes.posts.new,
        edit: (id: int) => buildRoute(RouteSchemes.posts.edit, id)
    },
    slides: {
        index: () => RouteSchemes.slides.index,
        new: () => RouteSchemes.slides.new,
        edit: (id: int) => buildRoute(RouteSchemes.slides.edit, id)
    },
    contactForms: {
        index: (pageIndex?: int, pageLength?: int) => withQueryString(RouteSchemes.contactForms.index, { pageIndex, pageLength })
    },
    newsletterSubscriptions: {
        index: (pageIndex?: int, pageLength?: int) => withQueryString(RouteSchemes.newsletterSubscriptions.index, { pageIndex, pageLength })
    },
    pageVisits: {
        index: (pageIndex?: int, pageLength?: int) => withQueryString(RouteSchemes.pageVisits.index, { pageIndex, pageLength })
    }
};

export const RouteSchemes = {
    account: {
        signIn: "/hesap/giris"
    },
    home: "/",
    categories: {
        index: "/kategoriler",
        new: "/kategoriler/yeni",
        edit: "/kategoriler/:id/duzenle"
    },
    products: {
        index: "/urunler",
        new: "/urunler/yeni",
        edit: "/urunler/:id/duzenle"
    },
    properties: {
        index: "/ozellik-tipleri",
        new: "/ozellik-tipleri/yeni",
        edit: "/ozellik-tipleri/:id/duzenle"
    },
    images: {
        index: "/gorsel-icerikler",
        new: "/gorsel-icerikler/yeni",
        edit: "/gorsel-icerikler/:id/duzenle"
    },
    posts: {
        index: "/makaleler",
        new: "/makaleler/yeni",
        edit: "/makaleler/:id/duzenle"
    },
    slides: {
        index: "/slaytlar",
        new: "/slaytlar/yeni",
        edit: "/slaytlar/:id/duzenle"
    },
    contactForms: {
        index: "/iletisim-formlari"
    },
    newsletterSubscriptions: {
        index: "/haber-bulten-kayitlari"
    },
    pageVisits: {
        index: "/sayfa-ziyaretleri"
    }
};

function buildRoute(
    uri: string,
    ...args: (string | number)[]) {
    let urix = uri;
    for (let i = 0; i < args.length; i++) {
        const param = args[i];

        let paramStartIndex = urix.indexOf(':');
        let paramEndIndex: number | undefined = urix.indexOf('/', paramStartIndex);
        if (paramEndIndex === -1) {
            paramEndIndex = undefined;
        }

        urix = urix.replace(urix.substring(paramStartIndex, paramEndIndex), param.toString());
    }

    return urix;
}