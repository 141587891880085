import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { Dispatch, bindActionCreators, Action } from 'redux';
import { Table, Button, ButtonGroup, Col, Row, Label } from 'reactstrap';
import BlockSpinner from '../components/BlockSpinner';
import { withToastUI } from '../components/Toast';
import { Routes } from '../Routes';
import { BasePage, BasePageProps } from './BasePage';
import Dialog from '../components/Dialog';
import { Nullable, int } from '../util/Types';
import { parseQueryString } from '../util/Utils';
import { dateTimeText, httpStatusText } from '../util/TextUtilities';
import { PaginatedListRequest } from '../contract/request/PaginatedListRequest';
import Pagination from '../components/Pagination';
import { PageVisit } from '../contract/dto/PageVisit';
import { getPageVisits } from '../services/PageVisit';

const mapStateToProps = (state: ApplicationState) => ({
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
    bindActionCreators(
        {
        },
        dispatch
    );

const LabelText = (props: { label?: string; text?: string; }) => (
    <Row>
        <Col md={3} className="text-right">
            <Label className="text-muted">{props.label}</Label>
        </Col>
        <Col md={9}>
            <p>
                {props.text}
            </p>
        </Col>
    </Row>
);

type PageVisitsProps =
    BasePageProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {

    };

type PageVisitsState = {
    loadingPageVisits: boolean;
    pageIndex: int;
    pageLength: int;
    pageVisits: Nullable<PageVisit[]>;
    totalCount: int;
    pageVisitToShow: Nullable<PageVisit>;
};

class PageVisits extends BasePage<PageVisitsProps, PageVisitsState> {
    constructor(props: PageVisitsProps) {
        super(props);

        this.state = {
            loadingPageVisits: false,
            pageIndex: -1,
            pageLength: -1,
            pageVisits: [],
            totalCount: 0,
            pageVisitToShow: null
        };
    }

    async componentDidMount() {
        this.setPageTitle('Sayfa Ziyaretleri');
        
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: PageVisitsProps) {
        let previousPagination = parseQueryString<PaginatedListRequest>(prevProps.location.search);
        let currentPagination = this.getPaginationParameters();

        if (previousPagination.pageIndex !== currentPagination.pageIndex ||
            previousPagination.pageLength !== currentPagination.pageLength) {
            await this.fetchData();
        }
    }

    private getPaginationParameters(): PaginatedListRequest {
        let paginationParameters = parseQueryString<PaginatedListRequest>(this.props.location.search);

        return {
            pageIndex: paginationParameters.pageIndex || 0,
            pageLength: paginationParameters.pageLength || 50
        };
    }

    private async fetchData() {
        let paginationParameters = this.getPaginationParameters();

        if (this.state.pageIndex === paginationParameters.pageIndex &&
            this.state.pageLength === paginationParameters.pageLength) {
            return;
        }

        this.setState({
            loadingPageVisits: true,
            pageVisits: null,
            totalCount: 0,
            pageIndex: paginationParameters.pageIndex,
            pageLength: paginationParameters.pageLength
        });

        let response = await getPageVisits(paginationParameters.pageIndex, paginationParameters.pageLength);

        this.setState({
            loadingPageVisits: false
        });

        if (response && response.success) {
            this.setState({
                pageVisits: response.data,
                totalCount: response.totalCount
            });
        }
        else {
            this.handleError(response);
        }
    }

    public render() {
        return (
            <>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Sayfa Ziyaretleri</h1>
                    {/* <div className="btn-toolbar mb-2 mb-md-0">
                        <Button className="btn btn-outline-primary btn-sm" disabled>
                            <i className="fa fa-mail-bulk mr-2"></i>Toplu Email Gönder
                        </Button>
                    </div> */}
                </div>

                <Pagination
                    pageIndex={this.getPaginationParameters().pageIndex}
                    pageLength={this.getPaginationParameters().pageLength}
                    totalCount={this.state.totalCount}
                    urlBuilder={(pi, pl) => Routes.pageVisits.index(pi, pl)}
                />

                <div className="table-responsive">
                    <Table responsive striped>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Ziyaret Tarihi</th>
                                <th>IP Adresi</th>
                                <th>Kaynak</th>
                                <th>Sayfa</th>
                                <th>Durum</th>
                                <th>İşlem</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(!this.state.loadingPageVisits && this.state.pageVisits) && this.state.pageVisits.map((pageVisit, i) => (
                                <tr key={i}>
                                    <th scope="row">{pageVisit.id}</th>
                                    <td>{dateTimeText(pageVisit.visitTime)}</td>
                                    <td>{pageVisit.ipAddress}</td>
                                    <td>{pageVisit.referrer}</td>
                                    <td>{pageVisit.page}</td>
                                    <td>{httpStatusText(pageVisit.statusCode)}</td>
                                    <td>
                                        <ButtonGroup>
                                            <Button size="sm" color="info" onClick={() => this.setState({ pageVisitToShow: pageVisit })}><i className="fa fa-edit"></i></Button>
                                        </ButtonGroup>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>

                <Pagination
                    pageIndex={this.getPaginationParameters().pageIndex}
                    pageLength={this.getPaginationParameters().pageLength}
                    totalCount={this.state.totalCount}
                    urlBuilder={(pi, pl) => Routes.pageVisits.index(pi, pl)}
                />

                {(this.state.loadingPageVisits) && (<BlockSpinner />)}

                <Dialog
                    title="Sayfa Ziyareti"
                    isOpen={this.state.pageVisitToShow !== null}
                    onClose={() => this.setState({ pageVisitToShow: null })}
                >
                    {this.state.pageVisitToShow && (
                        <>
                            <LabelText label="Ziyaret tarihi" text={dateTimeText(this.state.pageVisitToShow.visitTime)} />
                            <LabelText label="IP adresi" text={this.state.pageVisitToShow.ipAddress} />
                            <LabelText label="Kaynak" text={this.state.pageVisitToShow.referrer} />
                            <LabelText label="Sayfa" text={this.state.pageVisitToShow.page} />
                            <LabelText label="Durum" text={httpStatusText(this.state.pageVisitToShow.statusCode)} />
                        </>
                    )}
                </Dialog>
            </>
        );
    }
}

export default withToastUI(connect(
    mapStateToProps,
    mapDispatchToProps
)(PageVisits));