import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { Dispatch, bindActionCreators, Action } from 'redux';
import { Table, Button, ButtonGroup, Row, Col } from 'reactstrap';
import BlockSpinner from '../components/BlockSpinner';
import { withToastUI } from '../components/Toast';
import { Link } from 'react-router-dom';
import { Routes } from '../Routes';
import { BasePage, BasePageProps } from './BasePage';
import Prompt from '../components/Prompt';
import { Nullable, int } from '../util/Types';
import { deleteImage, getImages } from '../services/Image';
import ImageFilterForm, { ImageFilterFormFields, ImageFilterFormName } from '../forms/ImageFilterForm';
import { ImageType } from '../contract/common/ImageType';
import { parseEnum } from '../util/Utils';
import { submit } from 'redux-form';
import { Image } from '../contract/dto/Image';
import { imageTypeText } from '../util/TextUtilities';

const mapStateToProps = (state: ApplicationState) => ({
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
    bindActionCreators(
        {
            submitForm: (form: string) => dispatch(submit(form))
        },
        dispatch
    );

type ImagesProps =
    BasePageProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {

    };

type ImagesState = {
    loadingImages: boolean;
    images: Nullable<Image[]>;
    imageToDelete: Nullable<int>;
    deleting: boolean;
};

class Images extends BasePage<ImagesProps, ImagesState> {
    constructor(props: ImagesProps) {
        super(props);

        this.state = {
            loadingImages: false,
            images: [],
            imageToDelete: null,
            deleting: false
        };
    }

    async componentDidMount() {
        this.setPageTitle('Görsel İçerikler');
        
        this.props.submitForm(ImageFilterFormName);
    }

    private async handleFormSubmit(data: ImageFilterFormFields) {
        this.setState({
            loadingImages: true,
            images: null
        });

        let imageType: ImageType;
        if (typeof data.imageType === "string") {
            imageType = parseEnum(data.imageType);
        }
        else if (!data.imageType) {
            imageType = ImageType.Gallery;
        }
        else {
            imageType = data.imageType;
        }

        let response = await getImages(imageType);

        this.setState({
            loadingImages: false
        });

        if (response && response.success) {
            this.setState({
                images: response.data
            });
        }
        else {
            this.handleError(response);
        }
    }

    async deleteProperty() {
        if (!this.state.imageToDelete) {
            return;
        }

        this.setState({
            deleting: true
        });

        let response = await deleteImage(this.state.imageToDelete);

        this.setState({
            deleting: false,
            imageToDelete: null
        });

        if (response && response.success) {
            this.props.push(
                'Başarılı',
                'Görsel içerik silindi.',
                'success');

            this.props.submitForm(ImageFilterFormName);
        }
        else {
            this.handleError(response);
        }
    }

    public render() {
        return (
            <>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Görsel İçerikler</h1>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <Link to={Routes.images.new()} className="btn btn-outline-primary btn-sm">
                            <i className="fa fa-plus mr-2"></i>Oluştur
                        </Link>
                    </div>
                </div>

                <Row>
                    <Col sm="12">
                        <ImageFilterForm
                            onSubmit={(data) => this.handleFormSubmit(data)}
                            disabled={this.state.loadingImages}
                            enableReinitialize
                            initialValues={{
                                imageType: ImageType.Gallery
                            }}
                        >
                            {this.state.loadingImages && (<BlockSpinner />)}
                        </ImageFilterForm>
                    </Col>
                </Row>

                <div className="table-responsive">
                    <Table responsive striped>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Tip</th>
                                <th>Açıklama</th>
                                <th>URL</th>
                                <th>İndirme URL</th>
                                <th>İşlem</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(!this.state.loadingImages && this.state.images) && this.state.images.map((image, i) => (
                                <tr key={i}>
                                    <th scope="row">{image.id}</th>
                                    <td>{imageTypeText(image.type)}</td>
                                    <td>{image.description}</td>
                                    <td>{image.url}</td>
                                    <td>{image.downloadUrl}</td>
                                    <td>
                                        <ButtonGroup>
                                            <Link to={Routes.images.edit(image.id)} className="btn btn-sm btn-info"><i className="fa fa-edit"></i></Link>
                                            <Button size="sm" color="danger" onClick={() => this.setState({ imageToDelete: image.id })}><i className="fa fa-trash"></i></Button>
                                        </ButtonGroup>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>

                {(this.state.loadingImages || this.state.deleting) && (<BlockSpinner />)}

                <Prompt
                    title="Görsel İçerik Sil"
                    text="Görsel içerik silinecek, bu işlem geri alınamaz. Emin misiniz?"
                    isOpen={this.state.imageToDelete !== null}
                    onClose={() => this.setState({ imageToDelete: null })}
                    onSubmit={async () => await this.deleteProperty()}
                />
            </>
        );
    }
}

export default withToastUI(connect(
    mapStateToProps,
    mapDispatchToProps
)(Images));