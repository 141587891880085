import * as React from 'react';
import { Field, reduxForm, InjectedFormProps, FieldArray, FieldArrayFieldsProps, FieldArrayMetaProps } from 'redux-form';
import { renderInput, renderTextArea, renderSelect, renderCheckbox, ValidationMeta } from '../components/FormElements';
import { required, uri, minLength } from '../util/Validation';
import { getFieldName, nameof } from '../util/Utils';
import { Form, Label, Button, FormGroup, Row, Col } from 'reactstrap';
import { int } from '../util/Types';
import { AddOrUpdateProductImage } from '../contract/request/AddOrUpdateProductImage';
import { AddOrUpdateProductProperty } from '../contract/request/AddOrUpdateProductProperty';
import { Category } from '../contract/dto/Category';
import { Property } from '../contract/dto/Property';

export type ProductFormFields = {
    categoryId: int;
    mainImageUrl: string;
    order: int;
    isFeatured: boolean;
    uri: string;
    name: string;
    description: string;
    summary: string;
    rawContent: string;
    images: AddOrUpdateProductImage[];
    properties: AddOrUpdateProductProperty[];
}

type ProductFormFieldsProps = {
    categories: Category[];
    properties: Property[];
    prefix?: string;
};

type RenderFieldsProps<T = {}> = {
    fields: FieldArrayFieldsProps<string>;
    meta: FieldArrayMetaProps;
} & T;

const renderImages = ({ fields, meta: { submitFailed, invalid, error } }: RenderFieldsProps) => (
    <ul className="list-unstyled">
        <li>
            <FormGroup>
                <Button size="sm" color="primary" onClick={() => fields.push('')}>
                    <i className="fa fa-plus"></i>
                </Button>
            </FormGroup>
        </li>
        {fields.map((hobby, index) => (
            <li key={index}>
                <Field
                    className="visibly-hidden"
                    name={`${hobby}.${nameof<AddOrUpdateProductImage>('productImageId')}`}
                    type="text"
                    component={renderInput}
                    hidden
                />
                <Row form className="form-group">
                    <Col xs={2} md={1}>
                        <Button size="sm" color="danger" onClick={() => fields.remove(index)}>
                            <i className="fa fa-trash"></i>
                        </Button>
                    </Col>
                    <Col xs={10} md={11}>
                        <Field
                            className="flex-grow-1"
                            name={`${hobby}.${nameof<AddOrUpdateProductImage>('url')}`}
                            type="text"
                            component={renderInput}
                            label={`Görsel (URL) #${index + 1}`}
                            validate={[required]}
                        />
                    </Col>
                </Row>
            </li>
        ))}
        {(submitFailed && invalid) && (
            <li className="error">
                <div className="invalid-feedback d-block">
                    {error}
                </div>
            </li>
        )}
    </ul>
);

const renderProperties = ({ properties, fields, meta: { submitFailed, invalid, error } }: RenderFieldsProps<{ properties: Property[] }>) => (
    <ul className="list-unstyled">
        <li>
            <FormGroup>
                <Button size="sm" color="primary" onClick={() => fields.push('')}>
                    <i className="fa fa-plus"></i>
                </Button>
            </FormGroup>
        </li>
        {fields.map((hobby, index) => (
            <li key={index}>
                <Field
                    className="visibly-hidden"
                    name={`${hobby}.${nameof<AddOrUpdateProductProperty>('productPropertyId')}`}
                    type="text"
                    component={renderInput}
                    hidden
                />
                <Row form className="form-group">
                    <Col xs={2} md={1}>
                        <Button size="sm" color="danger" onClick={() => fields.remove(index)}>
                            <i className="fa fa-trash"></i>
                        </Button>
                    </Col>
                    <Col xs={10} md={6} className="mb-2">
                        <Field
                            className="flex-grow-1"
                            name={`${hobby}.${nameof<AddOrUpdateProductProperty>('propertyId')}`}
                            component={renderSelect}
                            label="Özellik"
                            children={(
                                <>
                                    <option value="">Özel</option>
                                    {properties.map((property, i) => (
                                        <option key={i} value={property.id}>{property.name}</option>
                                    ))}
                                </>
                            )}
                        />
                    </Col>
                    <Col xs={12} md={5}>
                        <Field
                            className="flex-grow-1"
                            name={`${hobby}.${nameof<AddOrUpdateProductProperty>('value')}`}
                            type="text"
                            component={renderInput}
                            label={`Değer #${index + 1}`}
                            validate={[required]}
                        />
                    </Col>
                </Row>
            </li>
        ))}
        {(submitFailed && invalid) && (
            <li className="error">
                <div className="invalid-feedback d-block">
                    {error}
                </div>
            </li>
        )}
    </ul>
);

export function ProductFormFields(props: ProductFormFieldsProps) {
    return (
        <React.Fragment>
            <FormGroup>
                <Label for="categoryId">Kategori</Label>
                <Field
                    id="categoryId"
                    name={`${getFieldName<ProductFormFields>('categoryId', props.prefix)}`}
                    component={renderSelect}
                    label="Kategori"
                    validate={[required]}
                    children={props.categories.map((category, i) => <option key={i} value={category.id}>{category.name}</option>)}
                />
            </FormGroup>

            <FormGroup>
                <Label for="imageUrl">Görsel (URL)</Label>
                <Field
                    id="imageUrl"
                    name={`${getFieldName<ProductFormFields>('mainImageUrl', props.prefix)}`}
                    component={renderInput}
                    label="Görsel (URL)"
                    validate={[required]}
                />
            </FormGroup>

            <FormGroup>
                <Label for="order">Sıra numarası</Label>
                <Field
                    id="order"
                    name={`${getFieldName<ProductFormFields>('order', props.prefix)}`}
                    component={renderInput}
                    label="Sıra numarası"
                    validate={[required]}
                    type="number"
                />
            </FormGroup>

            <FormGroup check>
                <Label check>
                    <Field
                        id="isFeatured"
                        name={`${getFieldName<ProductFormFields>('isFeatured', props.prefix)}`}
                        component={renderCheckbox}
                        label="Ana Sayfa'da göster"
                        type="checkbox"
                    />{' '}Ana Sayfa'da göster
                    </Label>
            </FormGroup>

            <FormGroup>
                <Label for="uri">URI</Label>
                <Field
                    id="uri"
                    name={`${getFieldName<ProductFormFields>('uri', props.prefix)}`}
                    component={renderInput}
                    label="URI"
                    validate={[required, uri]}
                />
            </FormGroup>

            <FormGroup>
                <Label for="name">Ad</Label>
                <Field
                    id="name"
                    name={`${getFieldName<ProductFormFields>('name', props.prefix)}`}
                    component={renderInput}
                    label="Ad"
                    validate={[required]}
                />
            </FormGroup>

            <FormGroup>
                <Label for="description">Açıklama</Label>
                <Field
                    id="description"
                    name={`${getFieldName<ProductFormFields>('description', props.prefix)}`}
                    component={renderInput}
                    label="Açıklama"
                />
            </FormGroup>

            <FormGroup>
                <Label for="summary">Özet</Label>
                <Field
                    id="summary"
                    name={`${getFieldName<ProductFormFields>('summary', props.prefix)}`}
                    component={renderInput}
                    label="Özet"
                    validate={[required]}
                />
            </FormGroup>

            <FormGroup>
                <Label for="rawContent">İçerik</Label>
                <Field
                    id="rawContent"
                    name={`${getFieldName<ProductFormFields>('rawContent', props.prefix)}`}
                    component={renderTextArea}
                    label="İçerik"
                    validate={[required]}
                />
            </FormGroup>

            <h6 className="mt-3">Görseller</h6>
            <FieldArray
                name={`${getFieldName<ProductFormFields>('images', props.prefix)}`}
                component={renderImages}
                props={{

                }}
                validate={[required, minLength(1)]}
            />

            <h6 className="mt-3">Özellikler</h6>
            <FieldArray<{ properties: Property[] }>
                name={`${getFieldName<ProductFormFields>('properties', props.prefix)}`}
                component={renderProperties}
                props={{
                    properties: props.properties
                }}
            />
        </React.Fragment>
    );
}

type _ProductFormProps = ProductFormFieldsProps & {
    categories: Category[];
    properties: Property[];
    disabled?: boolean;
};

export type ProductFormProps = InjectedFormProps<ProductFormFields, _ProductFormProps> &
    _ProductFormProps;

class ProductForm extends React.PureComponent<ProductFormProps> {
    public render() {
        const { handleSubmit } = this.props;

        return (
            <Form onSubmit={(s) => handleSubmit(s)} noValidate disabled={this.props.disabled}>
                <ProductFormFields
                    properties={this.props.properties}
                    categories={this.props.categories}
                    prefix={this.props.prefix}
                />

                <Button type="submit" color="primary"><i className="fa fa-save mr-2"></i>Kaydet</Button>

                {this.props.children}
            </Form>
        );
    }
};

export const ProductFormName: string = 'product';

export default reduxForm<ProductFormFields, _ProductFormProps>({
    form: ProductFormName
})(ProductForm);