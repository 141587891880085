import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { Category } from '../contract/dto/Category';
import { Response } from '../contract/Response';
import { getCategories } from '../services/Category';
import { unexpectedErrorResponse } from '../util/Errors';
import { Nullable } from '../util/Types';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface CategoryState {
    loading: boolean;
    categories: Category[];
    response: Nullable<Response>;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestCategoriesAction {
    type: 'REQUEST_CATEGORIES';
}

interface ReceiveCategoriesAction {
    type: 'RECEIVE_CATEGORIES';
    categories: Category[];
}

interface ReceiveCategoriesErrorAction {
    type: 'RECEIVE_CATEGORIES_ERROR';
    response: Response;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = RequestCategoriesAction | ReceiveCategoriesAction | ReceiveCategoriesErrorAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestCategories: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            dispatch({ type: 'REQUEST_CATEGORIES' });

            let response = await getCategories();

            if (response && response.success) {
                dispatch({ type: 'RECEIVE_CATEGORIES', categories: response.data });
            }
            else {
                dispatch({ type: 'RECEIVE_CATEGORIES_ERROR', response: response || unexpectedErrorResponse() });
            }
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: CategoryState = {
    loading: false,
    categories: [],
    response: null
};

export const reducer: Reducer<CategoryState> = (state: CategoryState | undefined, incomingAction: Action): CategoryState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_CATEGORIES':
            return {
                ...state,
                loading: true,
                response: null
            };
        case 'RECEIVE_CATEGORIES':
            return {
                ...state,
                loading: false,
                categories: action.categories,
                response: null
            };
        case 'RECEIVE_CATEGORIES_ERROR':
            return {
                ...state,
                loading: false,
                categories: [],
                response: action.response
            };
        default:
            return state;
    }
};