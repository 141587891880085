import { fetchJson } from './Base';
import { AddPostRequest } from '../contract/request/AddPostRequest';
import { UpdatePostRequest } from '../contract/request/UpdatePostRequest';
import { ListResponse, DataResponse, Response } from '../contract/Response';
import { Nullable, int } from '../util/Types';
import { Post } from '../contract/dto/Post';

export type GetPostsResponse = ListResponse<Post>;
export async function getPosts(): Promise<Nullable<GetPostsResponse>> {
    return await fetchJson<GetPostsResponse>(`/api/posts`, {
        method: 'GET'
    });
}

export type AddPostResponse = DataResponse<Post>;
export async function addPost(
    request: AddPostRequest
): Promise<Nullable<AddPostResponse>> {
    return await fetchJson<AddPostResponse>(`/api/posts`, {
        method: 'POST',
        body: JSON.stringify(request)
    });
}

export type GetPostByIdResponse = DataResponse<Post>;
export async function getPostById(id: int): Promise<Nullable<GetPostByIdResponse>> {
    return await fetchJson<GetPostByIdResponse>(`/api/posts/${id}`, {
        method: 'GET'
    });
}

export type UpdatePostResponse = DataResponse<Post>;
export async function updatePost(
    id: int,
    request: UpdatePostRequest
): Promise<Nullable<UpdatePostResponse>> {
    return await fetchJson<UpdatePostResponse>(`/api/posts/${id}`, {
        method: 'PUT',
        body: JSON.stringify(request)
    });
}

export type DeletePostResponse = Response;
export async function deletePost(
    id: int
): Promise<Nullable<DeletePostResponse>> {
    return await fetchJson<DeletePostResponse>(`/api/posts/${id}`, {
        method: 'DELETE'
    });
}