import * as React from 'react';
import { Field, reduxForm, InjectedFormProps, formValueSelector } from 'redux-form';
import { renderInput, renderTextArea, renderSelect, renderFileInput } from '../components/FormElements';
import { required, uri, requiredFile, fileFormat } from '../util/Validation';
import { getFieldName, nameof } from '../util/Utils';
import { imageTypeText } from '../util/TextUtilities';
import { ImageType } from '../contract/common/ImageType';
import { Form, Label, Button, FormGroup, Row, Col, FormText } from 'reactstrap';
import { connect } from 'react-redux';

export type ImageFormFields = {
    description: string;
    downloadUrl: string;
    type: ImageType;
    url: string;
    file: FileList;
}

type ImageFormFieldsProps = {
    prefix?: string;
    url: string;
    file: FileList;
};

export function ImageFormFields(props: ImageFormFieldsProps) {
    return (
        <React.Fragment>
            <FormGroup>
                <Label for="type">Görsel Tipi</Label>
                <Field
                    id="type"
                    name={`${getFieldName<ImageFormFields>('type', props.prefix)}`}
                    component={renderSelect}
                    label="Görsel Tipi"
                    validate={[required]}
                    children={(
                        <>
                            <option value={ImageType.Gallery}>{imageTypeText(ImageType.Gallery)}</option>
                            <option value={ImageType.Document}>{imageTypeText(ImageType.Document)}</option>
                            <option value={ImageType.Custom}>{imageTypeText(ImageType.Custom)}</option>
                            <option value={ImageType.EmbedVideo}>{imageTypeText(ImageType.EmbedVideo)}</option>
                            <option value={ImageType.Client}>{imageTypeText(ImageType.Client)}</option>
                        </>
                    )}
                />
            </FormGroup>

            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label for="url">URL</Label>
                        <Field
                            id="url"
                            name={`${getFieldName<ImageFormFields>('url', props.prefix)}`}
                            component={renderInput}
                            label="URL"
                            validate={(!props.file || props.file.length <= 0) ? [required] : undefined}
                            disabled={props.file && props.file.length >= 1}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for="imageFile">Dosya Seç</Label>
                        <Field
                            id="imageFile"
                            name={`${getFieldName<ImageFormFields>('file', props.prefix)}`}
                            component={renderFileInput}
                            label="Dosya"
                            validate={(!props.url) ? [requiredFile, fileFormat] : undefined}
                            type="file"
                            accept=".jpg,.jpeg,.png,.pdf,image/jpeg,image/png,application/pdf"
                        />
                        <FormText color="muted">
                            Yalnızca jpg, png veya pdf formatı
                        </FormText>
                    </FormGroup>
                </Col>
            </Row>

            <FormGroup>
                <Label for="description">Açıklama</Label>
                <Field
                    id="description"
                    name={`${getFieldName<ImageFormFields>('description', props.prefix)}`}
                    component={renderInput}
                    label="Açıklama"
                />
            </FormGroup>

            <FormGroup>
                <Label for="downloadUrl">İndirme URL</Label>
                <Field
                    id="downloadUrl"
                    name={`${getFieldName<ImageFormFields>('downloadUrl', props.prefix)}`}
                    component={renderInput}
                    label="İndirme URL"
                    disabled={props.file && props.file.length >= 1 && props.file[0].type === "application/pdf"}
                />
            </FormGroup>
        </React.Fragment>
    );
}

type _ImageFormProps = ImageFormFieldsProps & React.PropsWithChildren<{
    disabled?: boolean;
}>;

export type ImageFormProps = InjectedFormProps<ImageFormFields, _ImageFormProps> &
    _ImageFormProps;

class ImageForm extends React.PureComponent<ImageFormProps> {
    public render() {
        const { handleSubmit } = this.props;

        return (
            <Form onSubmit={(s) => handleSubmit(s)} noValidate disabled={this.props.disabled}>
                <ImageFormFields
                    prefix={this.props.prefix}
                    file={this.props.file}
                    url={this.props.url}
                />

                <Button type="submit" color="primary"><i className="fa fa-save mr-2"></i>Kaydet</Button>

                {this.props.children}
            </Form>
        );
    }
};

export const ImageFormName: string = 'image';

const selector = formValueSelector(ImageFormName);

export default connect(
    (state) => ({
        url: selector(state, nameof<ImageFormFields>('url')),
        file: selector(state, nameof<ImageFormFields>('file')),
    })
)(reduxForm<ImageFormFields, _ImageFormProps>({
    form: ImageFormName
})(ImageForm));