import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as CategoryStore from '../store/Category';
import { Dispatch, bindActionCreators } from 'redux';
import { Button } from 'reactstrap';
import BlockSpinner from '../components/BlockSpinner';
import { withToastUI } from '../components/Toast';
import ProductForm, { ProductFormFields } from '../forms/ProductForm';
import { AddProductResponse, addProduct, UpdateProductResponse, updateProduct, getProductById } from '../services/Product';
import { GetPropertiesResponse, getProperties } from '../services/Property';
import { Nullable } from '../util/Types';
import { BasePage, BasePageProps } from './BasePage';
import { Property } from '../contract/dto/Property';
import { Product } from '../contract/dto/Product';
import { RouteComponentProps } from 'react-router';

const mapStateToProps = (state: ApplicationState) => ({
    ...state.category
});

const mapDispatchToProps = (dispatch: Dispatch<CategoryStore.KnownAction>) =>
    bindActionCreators(
        {
            ...CategoryStore.actionCreators
        },
        dispatch
    );

type EditProductProps =
    RouteComponentProps<{ id: string; }> &
    BasePageProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {

    };

type EditProductState = {
    loadingProperties: boolean;
    properties: Property[];
    loadingProduct: boolean;
    product: Nullable<Product>;
    loading: boolean;
    response: Nullable<UpdateProductResponse>;
};

class EditProduct extends BasePage<EditProductProps, EditProductState> {
    constructor(props: EditProductProps) {
        super(props);

        this.state = {
            loadingProperties: false,
            properties: [],
            loadingProduct: false,
            product: null,
            loading: false,
            response: null
        };
    }

    async componentDidMount() {
        this.setPageTitle('Ürün Düzenle');
        
        if (this.props.categories.length <= 0) {
            this.props.requestCategories();
        }

        await this.fetchProperties();
        await this.fetchData();
    }

    private getId() {
        let id = parseInt(this.props.match.params.id);

        if (isNaN(id)) {
            return null;
        }

        return id;
    }

    private async fetchData() {
        let id = this.getId();
        if (!id) {
            return;
        }

        this.setState({
            loadingProduct: true,
            product: null
        });

        let response = await getProductById(id);

        this.setState({
            loadingProduct: false
        });

        if (response && response.success) {
            this.setState({
                product: response.data
            });
        }
        else {
            this.handleError(response);
        }
    }

    private async fetchProperties() {
        this.setState({
            loadingProperties: true
        });

        let response = await getProperties();

        this.setState({
            loadingProperties: false
        });

        if (response && response.success) {
            this.setState({
                properties: response.data
            });
        }
        else {
            this.handleError(response);
        }
    }

    private async handleFormSubmit(data: ProductFormFields) {
        let id = this.getId();
        if (!id) {
            return;
        }

        this.setState({
            loading: true,
            response: null
        });

        let response = await updateProduct(id, {
            ...data,
            properties: data.properties ? data.properties : []
        });

        this.setState({
            loading: false,
            response: response
        });

        if (response && response.success) {
            this.props.push(
                'Başarılı',
                'Ürün güncellendi.',
                'success');

            this.redirect((routes) => routes.products.index());
        }
        else {
            this.handleError(response);
        }
    }

    mapProductToFormValues(): Partial<ProductFormFields> | undefined {
        if (this.state.loadingProduct ||
            !this.state.product) {
            return undefined;
        }
        const { product } = this.state;
        return {
            categoryId: product.categoryId,
            description: product.description,
            images: product.images.map(x => ({ productImageId: x.id, url: x.image.url })),
            isFeatured: product.isFeatured,
            mainImageUrl: product.mainImage.url,
            order: product.order,
            properties: product.properties.map(x => ({ productPropertyId: x.id, propertyId: x.propertyId, value: x.value })),
            name: product.name,
            rawContent: product.post ? product.post.rawContent : undefined,
            summary: product.post ? product.post.summary : undefined,
            uri: product.uri
        }
    }

    public render() {
        return (
            <>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Ürün Düzenle</h1>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <Button size="sm" outline color="primary">
                            <i className="fa fa-save"></i>{' '}Kaydet
                        </Button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-8">
                        <ProductForm
                            properties={this.state.properties}
                            categories={this.props.categories}
                            disabled={this.state.loading}
                            enableReinitialize
                            initialValues={this.mapProductToFormValues()}
                            onSubmit={(data) => this.handleFormSubmit(data)}
                        >
                            {(this.state.loading || this.state.loadingProperties) && (<BlockSpinner />)}
                        </ProductForm>
                    </div>
                </div>
            </>
        );
    }
}

export default withToastUI(connect(
    mapStateToProps,
    mapDispatchToProps
)(EditProduct));