import * as React from 'react';
import { Container, Row } from 'reactstrap';
import Navbar from './Navbar';
import Sidebar from './Sidebar';

export default (props: { children?: React.ReactNode }) => (
    <React.Fragment>
        <Navbar />
        <Container fluid>
            <Row>
                <Sidebar />
                <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-md-4">
                    {props.children}
                </main>
            </Row>
        </Container>
    </React.Fragment>
);
