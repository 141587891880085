import { fetchJson } from './Base';
import { DataResponse, Response } from '../contract/Response';
import { User } from '../contract/dto/User';
import { Nullable } from '../util/Types';
import { SignInRequest } from '../contract/request/SignInRequest';

export type SignInResponse = DataResponse<User>;
export async function signIn(request: SignInRequest): Promise<Nullable<SignInResponse>> {
    return await fetchJson<SignInResponse>(`/api/account/sign-in`, {
        method: 'POST',
        body: JSON.stringify(request)
    });
}

export type SignOutResponse = Response;
export async function signOut(): Promise<Nullable<SignOutResponse>> {
    return await fetchJson<SignOutResponse>(`/api/account/sign-out`, {
        method: 'POST'
    });
}