import * as React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { renderSelect } from '../components/FormElements';
import { required, minLength5, email } from '../util/Validation';
import { getFieldName } from '../util/Utils';
import { Form, Label, Input, Button, FormGroup, Card, CardTitle, CardText, CardBody } from 'reactstrap';
import { int, Nullable } from '../util/Types';
import { Category } from '../contract/dto/Category';

export type ProductFilterFormFields = {
    category: int;
}

type ProductFilterFormFieldsProps = {
    categories: Category[];
    prefix?: string;
};

export function ProductFilterFormFields(props: ProductFilterFormFieldsProps) {
    return (
        <React.Fragment>
            <FormGroup>
                <Label for="category">Kategori</Label>
                <Field
                    id="category"
                    name={`${getFieldName<ProductFilterFormFields>('category', props.prefix)}`}
                    component={renderSelect}
                    label="Kategori"
                    validate={[required]}
                    children={props.categories.map((category, i) => <option key={i} value={category.id}>{category.name}</option>)}
                />
            </FormGroup>
        </React.Fragment>
    );
}

type _ProductFilterFormProps = ProductFilterFormFieldsProps & {
    categories: Category[];
    disabled?: boolean;
};

export type ProductFilterFormProps = InjectedFormProps<ProductFilterFormFields, _ProductFilterFormProps> &
    _ProductFilterFormProps;

class ProductFilterForm extends React.PureComponent<ProductFilterFormProps> {
    public render() {
        const { handleSubmit } = this.props;

        return (
            <Card body>
                <CardTitle>İşlemler</CardTitle>
                <Form onSubmit={(s) => handleSubmit(s)} noValidate disabled={this.props.disabled}>
                    <CardBody>
                        <ProductFilterFormFields categories={this.props.categories} prefix={this.props.prefix} />
                    </CardBody>

                    <Button type="submit" color="primary">Filtrele</Button>

                    {this.props.children}
                </Form>
            </Card>
        );
    }
};

export const ProductFilterFormName: string = 'productFilter';

export default reduxForm<ProductFilterFormFields, _ProductFilterFormProps>({
    form: ProductFilterFormName
})(ProductFilterForm);