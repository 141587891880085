import * as React from 'react';
import { FormGroup, Label, Input, FormFeedback, FormText, CustomInput } from 'reactstrap';

export type ValidationMeta = {
    touched: boolean;
    invalid: boolean;
    error: string;
    warning: string;
};

type _InputProps = {
    input: any;
};

type FormGroupInputProps = _InputProps & {
    label: string;
    className: string;
    id: string;
    helperText: string;
    meta: ValidationMeta;
};
export const FormGroupInput = ({
    label,
    className,
    id,
    helperText,
    input,
    meta: { touched, invalid, error },
    ...custom
}: FormGroupInputProps) => (
        <>
            <FormGroup>
                <Label for={id}>{label}</Label>
                <Input valid={touched && !invalid && !error} invalid={touched && invalid} {...custom} />
                {touched && (
                    <FormFeedback valid={touched && !invalid}>{error}</FormFeedback>
                )}
                {/* <FormText>{helperText}</FormText> */}
            </FormGroup>
        </>
    );

type InputProps = _InputProps & {
    label: string;
    meta: ValidationMeta;
};

export const renderInput = ({
    label,
    input,
    meta: { touched, invalid, error, warning },
    ...custom
}: InputProps) => (
        <>
            <Input placeholder={label} valid={touched && !error} invalid={touched && !!error} {...input} {...custom} />
            {error && <FormFeedback>{error}</FormFeedback>}
            {!error && warning && <FormText color="warning">{warning}</FormText>}
        </>
    );

export const renderCheckbox = ({
    label,
    input,
    meta: { touched, invalid, error, warning },
    ...custom
}: InputProps) => (
        <>
            <Input type="checkbox" valid={touched && !error} invalid={touched && !!error} {...input} {...custom} />
            {error && <FormFeedback>{error}</FormFeedback>}
            {!error && warning && <FormText color="warning">{warning}</FormText>}
        </>
    );


export const renderTextArea = ({
    label,
    input,
    meta: { touched, invalid, error, warning },
    ...custom
}: InputProps) => (
        <>
            <Input type="textarea" placeholder={label} valid={touched && !error} invalid={touched && !!error} {...input} {...custom} />
            {error && <FormFeedback>{error}</FormFeedback>}
            {!error && warning && <FormText color="warning">{warning}</FormText>}
        </>
    );


type SelectProps = InputProps & React.PropsWithChildren<{
}>;

export const renderSelect = ({
    label,
    input,
    children,
    meta: { touched, invalid, error, warning },
    ...custom
}: SelectProps) => (
        <>
            <Input type="select" placeholder="label" valid={touched && !error} invalid={touched && !!error} {...input} {...custom}>
                {children}
            </Input>
            {error && <FormFeedback>{error}</FormFeedback>}
            {!error && warning && <FormText color="warning">{warning}</FormText>}
        </>
    );

type FileInputProps = InputProps & {
    className?: string;
};

export const renderFileInput = ({
    label,
    input,
    meta: { touched, invalid, error, warning },
    className,
    ...custom
}: FileInputProps) => (
        <>
            <input
                placeholder={label}
                className={"form-control-file" + ((touched && invalid) ? " is-invalid" : "") + (className ? (' ' + className) : '')}
                {...input}
                {...custom}
                value={undefined}
            />
            {error && <FormFeedback>{error}</FormFeedback>}
            {!error && warning && <FormText color="warning">{warning}</FormText>}
        </>
    );


type SwitchProps = InputProps & React.PropsWithChildren<{
}>;

export const renderSwitch = ({
    label,
    input,
    children,
    meta: { touched, invalid, error, warning },
    ...custom
}: SelectProps) => (
        <>
            <CustomInput type="checkbox"
                className="custom-switch"
                label={label}
                valid={touched && !error}
                invalid={touched && !!error}
                {...input}
                {...custom}
            />

            {error && <FormFeedback>{error}</FormFeedback>}
            {!error && warning && <FormText color="warning">{warning}</FormText>}
        </>
    );