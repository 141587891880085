import * as React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { renderSelect } from '../components/FormElements';
import { required, minLength5, email } from '../util/Validation';
import { getFieldName } from '../util/Utils';
import { Form, Label, Input, Button, FormGroup, Card, CardTitle, CardText, CardBody } from 'reactstrap';
import { int, Nullable } from '../util/Types';
import { Category } from '../contract/dto/Category';
import { ImageType } from '../contract/common/ImageType';
import { imageTypeText } from '../util/TextUtilities';

export type ImageFilterFormFields = {
    imageType: ImageType;
}

type ImageFilterFormFieldsProps = {
    prefix?: string;
};

export function ImageFilterFormFields(props: ImageFilterFormFieldsProps) {
    return (
        <React.Fragment>
            <FormGroup>
                <Label for="imageType">Görsel Tipi</Label>
                <Field
                    id="imageType"
                    name={`${getFieldName<ImageFilterFormFields>('imageType', props.prefix)}`}
                    component={renderSelect}
                    label="Görsel Tipi"
                    validate={[required]}
                    children={(
                        <>
                            <option value={ImageType.Gallery}>{imageTypeText(ImageType.Gallery)}</option>
                            <option value={ImageType.Document}>{imageTypeText(ImageType.Document)}</option>
                            <option value={ImageType.Custom}>{imageTypeText(ImageType.Custom)}</option>
                            <option value={ImageType.EmbedVideo}>{imageTypeText(ImageType.EmbedVideo)}</option>
                            <option value={ImageType.Client}>{imageTypeText(ImageType.Client)}</option>
                        </>
                    )}
                />
            </FormGroup>
        </React.Fragment>
    );
}

type _ImageFilterFormProps = ImageFilterFormFieldsProps & {
    disabled?: boolean;
};

export type ImageFilterFormProps = InjectedFormProps<ImageFilterFormFields, _ImageFilterFormProps> &
    _ImageFilterFormProps;

class ImageFilterForm extends React.PureComponent<ImageFilterFormProps> {
    public render() {
        const { handleSubmit } = this.props;

        return (
            <Card body>
                <CardTitle>İşlemler</CardTitle>
                <Form onSubmit={(s) => handleSubmit(s)} noValidate disabled={this.props.disabled}>
                    <CardBody>
                        <ImageFilterFormFields prefix={this.props.prefix} />
                    </CardBody>

                    <Button type="submit" color="primary">Filtrele</Button>

                    {this.props.children}
                </Form>
            </Card>
        );
    }
};

export const ImageFilterFormName: string = 'imageFilter';

export default reduxForm<ImageFilterFormFields, _ImageFilterFormProps>({
    form: ImageFilterFormName
})(ImageFilterForm);