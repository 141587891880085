import * as React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { renderInput, renderTextArea, renderCheckbox, renderSwitch } from '../components/FormElements';
import { required, uri } from '../util/Validation';
import { getFieldName } from '../util/Utils';
import { Form, Label, Button, FormGroup } from 'reactstrap';

export type SlideFormFields = {
    imageUrl: string;
    showOnMobile: boolean;
    title: string;
    description: string;
}

type SlideFormFieldsProps = {
    prefix?: string;
};

export function SlideFormFields(props: SlideFormFieldsProps) {
    return (
        <React.Fragment>
            <FormGroup>
                <Label for="title">Başlık</Label>
                <Field
                    id="title"
                    name={`${getFieldName<SlideFormFields>('title', props.prefix)}`}
                    component={renderInput}
                    label="Başlık"
                />
            </FormGroup>

            <FormGroup>
                <Label for="imageUrl">Banner Görsel (URL)</Label>
                <Field
                    id="imageUrl"
                    name={`${getFieldName<SlideFormFields>('imageUrl', props.prefix)}`}
                    component={renderInput}
                    label="Banner Görsel (URL)"
                    validate={[required]}
                />
            </FormGroup>
            
            <FormGroup>
                <Label for="showOnMobile">Mobil'de Göster</Label>
                <Field
                    id="showOnMobile"
                    name={`${getFieldName<SlideFormFields>('showOnMobile', props.prefix)}`}
                    component={renderSwitch}
                    label="Mobil'de Göster"
                    type="checkbox"
                />
            </FormGroup>
            
            <FormGroup>
                <Label for="description">Açıklama</Label>
                <Field
                    id="description"
                    name={`${getFieldName<SlideFormFields>('description', props.prefix)}`}
                    component={renderInput}
                    label="Açıklama"
                />
            </FormGroup>
        </React.Fragment>
    );
}

type _SlideFormProps = SlideFormFieldsProps & {
    disabled?: boolean;
};

export type SlideFormProps = InjectedFormProps<SlideFormFields, _SlideFormProps> &
    _SlideFormProps;

class SlideForm extends React.PureComponent<SlideFormProps> {
    public render() {
        const { handleSubmit } = this.props;

        return (
            <Form onSubmit={(s) => handleSubmit(s)} noValidate disabled={this.props.disabled}>
                <SlideFormFields prefix={this.props.prefix} />

                <Button type="submit" color="primary"><i className="fa fa-save mr-2"></i>Kaydet</Button>

                {this.props.children}
            </Form>
        );
    }
};

export const SlideFormName: string = 'slide';

export default reduxForm<SlideFormFields, _SlideFormProps>({
    form: SlideFormName
})(SlideForm);