import * as React from 'react';
import { WithToastUIProps } from './Toast';
import { Response } from '../contract/Response';
import { Nullable } from '../util/Types';

export type BaseComponentProps = WithToastUIProps & {

};

export class BaseComponent<
    TProps extends BaseComponentProps,
    TState = {},
    TSS = any> extends React.Component<TProps, TState, TSS> {
    handleError(
        response: Nullable<Response>) {
        this.props.push(
            'Hata',
            (response && response.errorMessage) ? response.errorMessage : 'Beklenmedik bir hata oluştu',
            'danger',
            (response && response.errorCode) ? `Hata kodu: ${response.errorCode}` : undefined);
    }
}