import * as React from 'react';
import { connect } from 'react-redux';
import { RouteProps, RouteComponentProps, Redirect, Route } from 'react-router';
import { ApplicationState } from '../store';
import { Routes } from '../Routes';

const mapStateToProps = (state: ApplicationState) => ({
    ...state.system
});

type PrivateRouteProps = RouteProps &
    ReturnType<typeof mapStateToProps> & {
        isPrivate?: boolean;
        component: React.ComponentType<RouteComponentProps> | React.ComponentType<any>,
        layout?: React.ComponentType
    };

function PrivateRoute(props: PrivateRouteProps) {
    const isAuthenticated = props.user !== null;

    if (!isAuthenticated && props.isPrivate) {
        return (
            <Redirect
                to={{
                    pathname: Routes.account.signIn(),
                    state: { from: props.location }
                }}
            />
        );
    }

    const {
        layout: Layout,
        component: Component,

        getUserResponse,
        loadingUser,
        user,
        children,
        exact,
        location,
        path,
        isPrivate,
        render,
        sensitive,
        strict,

        ...rest
    } = props;

    return (
        <Route {...rest} render={(props) => Layout ? (
            <Layout>
                <Component {...props} />
            </Layout>
        ) : (
                <Component {...props} />
            )}
        />
    );
}

export default connect(
    mapStateToProps
)(PrivateRoute);