import * as React from 'react';
import { Spinner } from 'reactstrap';

export default () => (
    <div className="d-flex align-items-center justify-content-center position-fixed" style={{
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backdropFilter: 'grayscale(0.5) opacity(0.8)',
        zIndex: 1021
    }}>
        <Spinner />
    </div>
);