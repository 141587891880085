import { fetchJson } from './Base';
import { AddPropertyRequest } from '../contract/request/AddPropertyRequest';
import { UpdatePropertyRequest } from '../contract/request/UpdatePropertyRequest';
import { ListResponse, DataResponse, Response } from '../contract/Response';
import { Nullable, int } from '../util/Types';
import { Property } from '../contract/dto/Property';

export type GetPropertiesResponse = ListResponse<Property>;
export async function getProperties(): Promise<Nullable<GetPropertiesResponse>> {
    return await fetchJson<GetPropertiesResponse>(`/api/properties`, {
        method: 'GET'
    });
}

export type AddPropertyResponse = DataResponse<Property>;
export async function addProperty(
    request: AddPropertyRequest
): Promise<Nullable<AddPropertyResponse>> {
    return await fetchJson<AddPropertyResponse>(`/api/properties`, {
        method: 'POST',
        body: JSON.stringify(request)
    });
}

export type GetPropertyByIdResponse = DataResponse<Property>;
export async function getPropertyById(id: int): Promise<Nullable<GetPropertyByIdResponse>> {
    return await fetchJson<GetPropertyByIdResponse>(`/api/properties/${id}`, {
        method: 'GET'
    });
}

export type UpdatePropertyResponse = DataResponse<Property>;
export async function updateProperty(
    id: int,
    request: UpdatePropertyRequest
): Promise<Nullable<UpdatePropertyResponse>> {
    return await fetchJson<UpdatePropertyResponse>(`/api/properties/${id}`, {
        method: 'PUT',
        body: JSON.stringify(request)
    });
}

export type DeletePropertyResponse = Response;
export async function deleteProperty(
    id: int
): Promise<Nullable<DeletePropertyResponse>> {
    return await fetchJson<DeletePropertyResponse>(`/api/properties/${id}`, {
        method: 'DELETE'
    });
}