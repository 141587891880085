import * as React from 'react';
import { Nav } from 'reactstrap';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Routes, RouteSchemes } from '../Routes';
import { urlSchemeEqualsUrl } from '../util/Utils';

const _NavItem = (props: RouteComponentProps & { scheme: string; route: string; icon: string; title: string; }) => {
    const isActive = urlSchemeEqualsUrl(props.scheme, props.location.pathname);

    return (
        <li className="nav-item">
            <Link to={props.route} className={"nav-link" + (isActive ? ' active' : '')}>
                <i className={`fa fa-${props.icon} mr-2`}></i>
                {props.title}
                {isActive && <span className="sr-only">(current)</span>}
            </Link>
        </li>
    );
}

const NavItem = withRouter(_NavItem);

export default () => (
    <Nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
        <div className="sidebar-sticky pt-3">
            <ul className="nav flex-column">
                <NavItem
                    scheme={RouteSchemes.home}
                    route={Routes.home()}
                    icon="house-user"
                    title="Panel"
                />
                <NavItem
                    scheme={RouteSchemes.categories.index}
                    route={Routes.categories.index()}
                    icon="tag"
                    title="Kategoriler"
                />
                <NavItem
                    scheme={RouteSchemes.products.index}
                    route={Routes.products.index()}
                    icon="shopping-cart"
                    title="Ürünler"
                />
                <NavItem
                    scheme={RouteSchemes.properties.index}
                    route={Routes.properties.index()}
                    icon="cogs"
                    title="Özellik Tipleri"
                />
                <NavItem
                    scheme={RouteSchemes.images.index}
                    route={Routes.images.index()}
                    icon="images"
                    title="Görsel İçerikler"
                />
                <NavItem
                    scheme={RouteSchemes.posts.index}
                    route={Routes.posts.index()}
                    icon="newspaper"
                    title="Makaleler"
                />
                <NavItem
                    scheme={RouteSchemes.slides.index}
                    route={Routes.slides.index()}
                    icon="photo-video"
                    title="Slaytlar"
                />
                {/* <li className="nav-item">
                    <Link to={Routes.home()} className="nav-link active">
                        <i className="fa fa-house-user mr-2"></i>
                        Panel
                        <span className="sr-only">(current)</span>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to={Routes.categories.index()} className="nav-link">
                        <i className="fa fa-tag mr-2"></i>
                        Kategoriler
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to={Routes.products.index()} className="nav-link">
                        <i className="fa fa-shopping-cart mr-2"></i>
                        Ürünler
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to={Routes.properties.index()} className="nav-link">
                        <i className="fa fa-cogs mr-2"></i>
                        Özellik Tipleri
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to={Routes.images.index()} className="nav-link">
                        <i className="fa fa-images mr-2"></i>
                        Görsel İçerikler
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to={Routes.posts.index()} className="nav-link">
                        <i className="fa fa-newspaper mr-2"></i>
                        Makaleler
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to={Routes.slides.index()} className="nav-link">
                        <i className="fa fa-photo-video mr-2"></i>
                        Slaytlar
                    </Link>
                </li> */}
            </ul>

            <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                <span>KANALLAR</span>
            </h6>
            <ul className="nav flex-column mb-2">
                <NavItem
                    scheme={RouteSchemes.contactForms.index}
                    route={Routes.contactForms.index()}
                    icon="envelope"
                    title="İletişim Formları"
                />
                <NavItem
                    scheme={RouteSchemes.newsletterSubscriptions.index}
                    route={Routes.newsletterSubscriptions.index()}
                    icon="inbox"
                    title="Haber Bülten Kayıtları"
                />
                {/* <li className="nav-item">
                    <Link to={Routes.contactForms.index()} className="nav-link">
                        <i className="fa fa-envelope mr-2"></i>
                        İletişim Formları
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to={Routes.newsletterSubscriptions.index()} className="nav-link">
                        <i className="fa fa-inbox mr-2"></i>
                        Haber Bülten Kayıtları
                    </Link>
                </li> */}
            </ul>

            <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                <span>RAPORLAR</span>
            </h6>
            <ul className="nav flex-column mb-2">
                <NavItem
                    scheme={RouteSchemes.pageVisits.index}
                    route={Routes.pageVisits.index()}
                    icon="chart-bar"
                    title="Sayfa Ziyaretleri"
                />
                {/* <li className="nav-item">
                    <Link to={Routes.pageVisits.index()} className="nav-link">
                        <i className="fa fa-chart-bar mr-2"></i>
                        Sayfa Ziyaretleri
                    </Link>
                </li> */}
            </ul>
        </div>
    </Nav>
);