import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { Dispatch, bindActionCreators, Action } from 'redux';
import { Button } from 'reactstrap';
import BlockSpinner from '../components/BlockSpinner';
import { withToastUI } from '../components/Toast';
import { Nullable } from '../util/Types';
import { BasePage, BasePageProps } from './BasePage';
import { RouteComponentProps } from 'react-router';
import { getPostById, updatePost } from '../services/Post';
import { Post } from '../contract/dto/Post';
import PostForm, { PostFormFields } from '../forms/PostForm';
import { Slide } from '../contract/dto/Slide';
import { getSlideById, updateSlide } from '../services/Slide';
import SlideForm, { SlideFormFields } from '../forms/SlideForm';

const mapStateToProps = (state: ApplicationState) => ({
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
    bindActionCreators(
        {
        },
        dispatch
    );

type EditSlideProps =
    RouteComponentProps<{ id: string; }> &
    BasePageProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {

    };

type EditSlideState = {
    loadingSlide: boolean;
    slide: Nullable<Slide>;
    loading: boolean;
};

class EditSlide extends BasePage<EditSlideProps, EditSlideState> {
    constructor(props: EditSlideProps) {
        super(props);

        this.state = {
            loadingSlide: false,
            slide: null,
            loading: false
        };
    }

    async componentDidMount() {
        this.setPageTitle('Slayt Düzenle');
        
        await this.fetchData();
    }

    private getId() {
        let id = parseInt(this.props.match.params.id);

        if (isNaN(id)) {
            return null;
        }

        return id;
    }

    private async fetchData() {
        let id = this.getId();
        if (!id) {
            return;
        }

        this.setState({
            loadingSlide: true,
            slide: null
        });

        let response = await getSlideById(id);

        this.setState({
            loadingSlide: false
        });

        if (response && response.success) {
            this.setState({
                slide: response.data
            });
        }
        else {
            this.handleError(response);
        }
    }

    private async handleFormSubmit(data: SlideFormFields) {
        let id = this.getId();
        if (!id) {
            return;
        }

        this.setState({
            loading: true
        });

        let response = await updateSlide(
            id,
            {
                ...data
            });

        this.setState({
            loading: false
        });

        if (response && response.success) {
            this.props.push(
                'Başarılı',
                'Slayt güncellendi.',
                'success');

            this.redirect((routes) => routes.slides.index());
        }
        else {
            this.handleError(response);
        }
    }

    mapSlideToFormValues(): Partial<SlideFormFields> | undefined {
        if (this.state.loadingSlide ||
            !this.state.slide) {
            return undefined;
        }

        const { slide } = this.state;

        console.log(slide);
        
        return {
            description: slide.description,
            imageUrl: slide.imageUrl,
            showOnMobile: slide.showOnMobile,
            title: slide.title
        }
    }

    public render() {
        return (
            <>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Slayt Düzenle</h1>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <Button size="sm" outline color="primary">
                            <i className="fa fa-save"></i>{' '}Kaydet
                        </Button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-8">
                        <SlideForm
                            disabled={this.state.loadingSlide || this.state.loading || !this.state.slide}
                            enableReinitialize
                            initialValues={this.mapSlideToFormValues()}
                            onSubmit={(data) => this.handleFormSubmit(data)}
                        >
                            {(this.state.loading || this.state.loadingSlide) && (<BlockSpinner />)}
                        </SlideForm>
                    </div>
                </div>
            </>
        );
    }
}

export default withToastUI(connect(
    mapStateToProps,
    mapDispatchToProps
)(EditSlide));