import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as CategoryStore from '../store/Category';
import { Dispatch, bindActionCreators } from 'redux';
import { Button } from 'reactstrap';
import BlockSpinner from '../components/BlockSpinner';
import { withToastUI } from '../components/Toast';
import CategoryForm, { CategoryFormFields } from '../forms/CategoryForm';
import { AddCategoryResponse, addCategory } from '../services/Category';
import { Nullable } from '../util/Types';
import { BasePage, BasePageProps } from './BasePage';
import { uploadFile } from '../services/Upload';

const mapStateToProps = (state: ApplicationState) => ({
  ...state.category
});

const mapDispatchToProps = (dispatch: Dispatch<CategoryStore.KnownAction>) =>
  bindActionCreators(
    {
      ...CategoryStore.actionCreators
    },
    dispatch
  );

type NewCategoryProps =
  BasePageProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {

  };

type NewCategoryState = {
  loading: boolean;
  response: Nullable<AddCategoryResponse>;
};

class NewCategory extends BasePage<NewCategoryProps, NewCategoryState> {
  constructor(props: NewCategoryProps) {
    super(props);

    this.state = {
      loading: false,
      response: null
    };
  }

  componentDidMount() {
    this.setPageTitle('Yeni Kategori');
  }

  private async handleFormSubmit(data: CategoryFormFields) {
    this.setState({
      loading: true,
      response: null
    });

    if (data.imageFile && data.imageFile.length) {
      const fileUploadResponse = await uploadFile({
        file: data.imageFile[0],
        folder: 'yaylaoglu.com/images/categories'
      });

      if (!fileUploadResponse || !fileUploadResponse.success) {
        this.handleError(fileUploadResponse);

        return;
      }

      // Set image url to upload result url
      data.imageUrl = fileUploadResponse.data.url;
    }

    let response = await addCategory({
      ...data
    });

    this.setState({
      loading: false,
      response: response
    });

    if (response && response.success) {
      this.props.push(
        'Başarılı',
        'Kategori eklendi.',
        'success');

      this.props.requestCategories();
      this.redirect((routes) => routes.categories.index());
    }
    else {
      this.handleError(response);
    }
  }

  public render() {
    return (
      <>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">Yeni Kategori</h1>
          <div className="btn-toolbar mb-2 mb-md-0">
            <Button size="sm" outline color="primary">
              <i className="fa fa-save"></i>{' '}Kaydet
            </Button>
          </div>
        </div>

        <div className="row">
          <div className="col-md-8">
            <CategoryForm
              categories={this.props.categories}
              disabled={this.state.loading}
              onSubmit={(data: any) => this.handleFormSubmit(data)}
            >
              {this.state.loading && (<BlockSpinner />)}
            </CategoryForm>
          </div>
        </div>
      </>
    );
  }
}

export default withToastUI(connect(
  mapStateToProps,
  mapDispatchToProps
)(NewCategory));