import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { Dispatch, bindActionCreators, Action } from 'redux';
import { Button } from 'reactstrap';
import BlockSpinner from '../components/BlockSpinner';
import { withToastUI } from '../components/Toast';
import { addSlide } from '../services/Slide';
import { BasePage, BasePageProps } from './BasePage';
import SlideForm, { SlideFormFields } from '../forms/SlideForm';

const mapStateToProps = (state: ApplicationState) => ({
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
    bindActionCreators(
        {
        },
        dispatch
    );

type NewSlideProps =
    BasePageProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {

    };

type NewSlideState = {
    loading: boolean;
};

class NewSlide extends BasePage<NewSlideProps, NewSlideState> {
    constructor(props: NewSlideProps) {
        super(props);

        this.state = {
            loading: false
        };
    }

    componentDidMount() {
        this.setPageTitle('Yeni Makale');
    }

    private async handleFormSubmit(data: SlideFormFields) {
        this.setState({
            loading: true
        });

        let response = await addSlide({
            ...data
        });

        this.setState({
            loading: false
        });

        if (response && response.success) {
            this.props.push(
                'Başarılı',
                'Slayt eklendi.',
                'success');

            this.redirect((routes) => routes.slides.index());
        }
        else {
            this.handleError(response);
        }
    }

    public render() {
        return (
            <>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Yeni Slayt</h1>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <Button size="sm" outline color="primary">
                            <i className="fa fa-save"></i>{' '}Kaydet
                        </Button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-8">
                        <SlideForm
                            disabled={this.state.loading}
                            onSubmit={(data) => this.handleFormSubmit(data)}
                        >
                            {this.state.loading && (<BlockSpinner />)}
                        </SlideForm>
                    </div>
                </div>
            </>
        );
    }
}

export default withToastUI(connect(
    mapStateToProps,
    mapDispatchToProps
)(NewSlide));