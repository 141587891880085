import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { Dispatch, bindActionCreators, Action } from 'redux';
import { Table, Button, ButtonGroup, Col, Row, Label } from 'reactstrap';
import BlockSpinner from '../components/BlockSpinner';
import { withToastUI } from '../components/Toast';
import { Routes } from '../Routes';
import { BasePage, BasePageProps } from './BasePage';
import Prompt from '../components/Prompt';
import Dialog from '../components/Dialog';
import { Nullable, int } from '../util/Types';
import { parseQueryString } from '../util/Utils';
import { dateTimeText } from '../util/TextUtilities';
import { ContactForm } from '../contract/dto/ContactForm';
import { PaginatedListRequest } from '../contract/request/PaginatedListRequest';
import { getContactForms, deleteContactForm } from '../services/ContactForm';
import Pagination from '../components/Pagination';

const mapStateToProps = (state: ApplicationState) => ({
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
    bindActionCreators(
        {
        },
        dispatch
    );

const LabelText = (props: { label?: string; text?: string; }) => (
    <Row>
        <Col md={3} className="text-right">
            <Label className="text-muted">{props.label}</Label>
        </Col>
        <Col md={9}>
            <p>
                {props.text}
            </p>
        </Col>
    </Row>
);

type ContactFormsProps =
    BasePageProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {

    };

type ContactFormsState = {
    loadingContactForms: boolean;
    pageIndex: int;
    pageLength: int;
    contactForms: Nullable<ContactForm[]>;
    totalCount: int;
    contactFormToDelete: Nullable<int>;
    contactFormToShow: Nullable<ContactForm>;
    deleting: boolean;
};

class ContactForms extends BasePage<ContactFormsProps, ContactFormsState> {
    constructor(props: ContactFormsProps) {
        super(props);

        this.state = {
            loadingContactForms: false,
            pageIndex: -1,
            pageLength: -1,
            contactForms: [],
            totalCount: 0,
            contactFormToDelete: null,
            contactFormToShow: null,
            deleting: false
        };
    }

    async componentDidMount() {
        this.setPageTitle('İletişim Formları');
        
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: ContactFormsProps) {
        let previousPagination = parseQueryString<PaginatedListRequest>(prevProps.location.search);
        let currentPagination = this.getPaginationParameters();

        if (previousPagination.pageIndex !== currentPagination.pageIndex ||
            previousPagination.pageLength !== currentPagination.pageLength) {
            await this.fetchData();
        }
    }

    private getPaginationParameters(): PaginatedListRequest {
        let paginationParameters = parseQueryString<PaginatedListRequest>(this.props.location.search);

        return {
            pageIndex: paginationParameters.pageIndex || 0,
            pageLength: paginationParameters.pageLength || 50
        };
    }

    private async fetchData() {
        let paginationParameters = this.getPaginationParameters();

        if (this.state.pageIndex === paginationParameters.pageIndex &&
            this.state.pageLength === paginationParameters.pageLength) {
            return;
        }

        this.setState({
            loadingContactForms: true,
            contactForms: null,
            totalCount: 0,
            pageIndex: paginationParameters.pageIndex,
            pageLength: paginationParameters.pageLength
        });

        let response = await getContactForms(paginationParameters.pageIndex, paginationParameters.pageLength);

        this.setState({
            loadingContactForms: false
        });

        if (response && response.success) {
            this.setState({
                contactForms: response.data,
                totalCount: response.totalCount
            });
        }
        else {
            this.handleError(response);
        }
    }

    async deleteProperty() {
        if (!this.state.contactFormToDelete) {
            return;
        }

        this.setState({
            deleting: true
        });

        let response = await deleteContactForm(this.state.contactFormToDelete);

        this.setState({
            deleting: false,
            contactFormToDelete: null
        });

        if (response && response.success) {
            this.props.push(
                'Başarılı',
                'İletişim formu silindi.',
                'success');

            this.fetchData();
        }
        else {
            this.handleError(response);
        }
    }

    public render() {
        return (
            <>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">İletişim Formları</h1>
                    {/* <div className="btn-toolbar mb-2 mb-md-0">
                        <Link to={Routes.properties.new()} className="btn btn-outline-primary btn-sm">
                            <i className="fa fa-plus mr-2"></i>Oluştur
                        </Link>
                    </div> */}
                </div>

                <Pagination
                    pageIndex={this.getPaginationParameters().pageIndex}
                    pageLength={this.getPaginationParameters().pageLength}
                    totalCount={this.state.totalCount}
                    urlBuilder={(pi, pl) => Routes.contactForms.index(pi, pl)}
                />

                <div className="table-responsive">
                    <Table responsive striped>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Ad Soyad</th>
                                <th>E-posta</th>
                                <th>Telefon Numarası</th>
                                <th>Kayıt Tarihi</th>
                                <th>İşlem</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(!this.state.loadingContactForms && this.state.contactForms) && this.state.contactForms.map((contactForm, i) => (
                                <tr key={i}>
                                    <th scope="row">{contactForm.id}</th>
                                    <td>{contactForm.nameSurname}</td>
                                    <td>{contactForm.email}</td>
                                    <td>{contactForm.phone}</td>
                                    <td>{dateTimeText(contactForm.addedTime)}</td>
                                    <td>
                                        <ButtonGroup>
                                            <Button size="sm" color="info" onClick={() => this.setState({ contactFormToShow: contactForm })}><i className="fa fa-edit"></i></Button>
                                            <Button size="sm" color="danger" onClick={() => this.setState({ contactFormToDelete: contactForm.id })}><i className="fa fa-trash"></i></Button>
                                        </ButtonGroup>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>

                <Pagination
                    pageIndex={this.getPaginationParameters().pageIndex}
                    pageLength={this.getPaginationParameters().pageLength}
                    totalCount={this.state.totalCount}
                    urlBuilder={(pi, pl) => Routes.contactForms.index(pi, pl)}
                />

                {(this.state.loadingContactForms || this.state.deleting) && (<BlockSpinner />)}

                <Prompt
                    title="İletişim Formu Sil"
                    text="İletişim formu silinecek, bu işlem geri alınamaz. Emin misiniz?"
                    isOpen={this.state.contactFormToDelete !== null}
                    onClose={() => this.setState({ contactFormToDelete: null })}
                    onSubmit={async () => await this.deleteProperty()}
                />

                <Dialog
                    title="İletişim Formu"
                    isOpen={this.state.contactFormToShow !== null}
                    onClose={() => this.setState({ contactFormToShow: null })}
                >
                    {this.state.contactFormToShow && (
                        <>
                            <LabelText label="Ad soyad" text={this.state.contactFormToShow.nameSurname} />
                            <LabelText label="E-posta adresi" text={this.state.contactFormToShow.email} />
                            <LabelText label="Telefon numarası" text={this.state.contactFormToShow.phone} />
                            <LabelText label="Eklenme tarihi" text={dateTimeText(this.state.contactFormToShow.addedTime)} />
                            <LabelText label="İleti" text={this.state.contactFormToShow.message} />
                        </>
                    )}
                </Dialog>
            </>
        );
    }
}

export default withToastUI(connect(
    mapStateToProps,
    mapDispatchToProps
)(ContactForms));