import { fetchJson } from './Base';
import { DataResponse } from '../contract/Response';
import { User } from '../contract/dto/User';
import { Nullable } from '../util/Types';

export type GetUserResponse = DataResponse<User>;
export async function getUser(): Promise<Nullable<GetUserResponse>> {
    return await fetchJson<GetUserResponse>(`/api/users/self`, {
        method: 'GET'
    });
}