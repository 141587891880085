import { RouteComponentProps, StaticContext } from 'react-router';
import { Routes } from '../Routes';
import { ApplicationState } from '../store';
import * as SystemStore from '../store/System';
import { bindActionCreators, Dispatch } from 'redux';
import { BaseComponent, BaseComponentProps } from '../components/BaseComponent';

export const mapStateToProps = (state: ApplicationState) => ({
    ...state.system
});

export const mapDispatchToProps = (dispatch: Dispatch<SystemStore.KnownAction>) =>
    bindActionCreators(
        {
            ...SystemStore.actionCreators
        },
        dispatch
    );

export type BasePageProps =
    BaseComponentProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> &
    RouteComponentProps<{}, StaticContext, { from?: string; }> & {

    };

export class BasePage<
    TProps extends BasePageProps,
    TState = {},
    TSS = any> extends BaseComponent<TProps, TState, TSS> {
    protected redirect(route: (routes: typeof Routes) => string) {
        this.props.history.push(route(Routes));
    }

    protected setPageTitle(title: string, absolute?: boolean) {
        window.document.title = absolute ? title : (title + (title ? ' - ' : '') + 'Aware CMS');
    }

    protected redirectBack() {
        if (this.props.location && this.props.location.state && this.props.location.state.from) {
            this.props.history.push(this.props.location.state.from);
        }
        else {
            this.props.history.push(Routes.home());
        }
    }
}