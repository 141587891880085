import { Action, Reducer } from 'redux';
import { Nullable } from '../util/Types';
import { AppThunkAction } from '.';
import { User } from '../contract/dto/User';
import { GetUserResponse, getUser } from '../services/User';

export interface SystemState {
    loadingUser: boolean;
    user: Nullable<User>;
    getUserResponse: Nullable<GetUserResponse>;
}

export interface RequestUserAction { type: 'REQUEST_USER' }
export interface ReceiveUserAction { type: 'RECEIVE_USER', user: Nullable<User>, response: Nullable<GetUserResponse> }
export interface SetUserAction { type: 'SET_USER', user: Nullable<User> }

export type KnownAction = RequestUserAction | ReceiveUserAction | SetUserAction;


export const actionCreators = {
    requestUser: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.system && !appState.system.user) {
            dispatch({ type: 'REQUEST_USER' });

            let response = await getUser();

            dispatch({ type: 'RECEIVE_USER', user: response && response.data, response: response });
        }
    },
    setUser: (user: Nullable<User>) => ({ type: 'SET_USER', user: user } as SetUserAction)
};


const unloadedState: SystemState = {
    loadingUser: false,
    user: null,
    getUserResponse: null
};

export const reducer: Reducer<SystemState> = (state: SystemState | undefined, incomingAction: Action): SystemState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_USER':
            return {
                ...state,
                loadingUser: true,
                user: null,
                getUserResponse: null
            };
        case 'RECEIVE_USER':
            return {
                ...state,
                loadingUser: false,
                user: action.user,
                getUserResponse: action.response
            };
        case 'SET_USER':
            return {
                ...state,
                loadingUser: false,
                user: action.user,
                getUserResponse: null
            };
        default:
            return state;
    }
};