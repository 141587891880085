import * as React from 'react';
import { Navbar as RSNavbar } from 'reactstrap';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Routes } from '../Routes';
import { signOut } from '../services/Account';
import { withToastUI, WithToastUIProps } from './Toast';
import { Dispatch, bindActionCreators } from 'redux';
import { ApplicationState } from '../store';
import * as SystemStore from '../store/System';
import { connect } from 'react-redux';
import BlockSpinner from './BlockSpinner';

const mapStateToProps = (state: ApplicationState) => ({
    ...state.system
});

const mapDispatchToProps = (dispatch: Dispatch<SystemStore.KnownAction>) =>
    bindActionCreators(
        {
            ...SystemStore.actionCreators
        },
        dispatch
    );

type NavbarProps =
    RouteComponentProps &
    WithToastUIProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {

    };

function Navbar(props: NavbarProps) {
    const [loading, setLoading] = React.useState<boolean>(false);

    async function signUserOut() {
        setLoading(true);

        let response = await signOut();

        setLoading(false);

        if (response && response.success) {
            props.push(
                'Başarılı',
                'Çıkış yapıldı yönlendiriliyorsunuz...',
                'success');

            props.setUser(null);

            props.history.push(Routes.account.signIn());
        }
        else {
            props.push(
                'Hata',
                (response && response.errorMessage) ? response.errorMessage : 'Beklenmedik bir hata oluştu',
                'danger',
                (response && response.errorCode) ? `Hata kodu: ${response.errorCode}` : undefined);
        }
    }

    return (
        <>
            <RSNavbar dark sticky="top" className="bg-dark flex-md-nowrap p-0 shadow">
                <Link to={Routes.home()} className="col-md-3 col-lg-2 navbar-brand">Yönetim Paneli</Link>
                <ul className="navbar-nav px-3">
                    <li className="nav-item text-nowrap">
                        <a
                            href="#"
                            className="nav-link"
                            onClick={async (e) => {
                                e.preventDefault();

                                await signUserOut();

                                return false;
                            }}
                        >
                            Çıkış yap
                        </a>
                    </li>
                </ul>
            </RSNavbar>

            {loading && (<BlockSpinner />)}
        </>
    );
}

export default withToastUI(withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Navbar)));