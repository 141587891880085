import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { Dispatch, bindActionCreators, Action } from 'redux';
import { Button } from 'reactstrap';
import BlockSpinner from '../components/BlockSpinner';
import { withToastUI } from '../components/Toast';
import { addProperty, getPropertyById, updateProperty } from '../services/Property';
import { BasePage, BasePageProps } from './BasePage';
import PropertyForm, { PropertyFormFields } from '../forms/PropertyForm';
import { RouteComponentProps } from 'react-router';
import { Property } from '../contract/dto/Property';
import { Nullable } from '../util/Types';

const mapStateToProps = (state: ApplicationState) => ({
    // ...state.category
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
    bindActionCreators(
        {
            // ...CategoryStore.actionCreators
        },
        dispatch
    );

type EditPropertyProps =
    RouteComponentProps<{ id: string; }> &
    BasePageProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {

    };

type EditPropertyState = {
    loadingProperty: boolean;
    property: Nullable<Property>;
    loading: boolean;
};

class EditProperty extends BasePage<EditPropertyProps, EditPropertyState> {
    constructor(props: EditPropertyProps) {
        super(props);

        this.state = {
            loadingProperty: false,
            property: null,
            loading: false
        };
    }

    async componentDidMount() {
        this.setPageTitle('Özellik Tipi Düzenle');
        
        await this.fetchData();
    }

    private getId() {
        let id = parseInt(this.props.match.params.id);

        if (isNaN(id)) {
            return null;
        }

        return id;
    }

    private async fetchData() {
        let id = this.getId();
        if (!id) {
            return;
        }

        this.setState({
            loadingProperty: true,
            property: null
        });

        let response = await getPropertyById(id);

        this.setState({
            loadingProperty: false
        });

        if (response && response.success) {
            this.setState({
                property: response.data
            });
        }
        else {
            this.handleError(response);
        }
    }

    private async handleFormSubmit(data: PropertyFormFields) {
        let id = this.getId();
        if (!id) {
            return;
        }

        this.setState({
            loading: true
        });

        let response = await updateProperty(
            id,
            {
                ...data
            });

        this.setState({
            loading: false
        });

        if (response && response.success) {
            this.props.push(
                'Başarılı',
                'Özellik tipi güncellendi.',
                'success');

            this.redirect((routes) => routes.properties.index());
        }
        else {
            this.handleError(response);
        }
    }

    mapPropertyToFormValues(): Partial<PropertyFormFields> | undefined {
        if (this.state.loadingProperty ||
            !this.state.property) {
            return undefined;
        }
        
        const { property } = this.state;

        return {
            name: property.name
        }
    }

    public render() {
        return (
            <>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Özellik Tipi Düzenle</h1>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <Button size="sm" outline color="primary">
                            <i className="fa fa-save"></i>{' '}Kaydet
                        </Button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-8">
                        <PropertyForm
                            disabled={this.state.loading}
                            enableReinitialize
                            initialValues={this.mapPropertyToFormValues()}
                            onSubmit={(data) => this.handleFormSubmit(data)}
                        >
                            {(this.state.loading || this.state.loadingProperty) && (<BlockSpinner />)}
                        </PropertyForm>
                    </div>
                </div>
            </>
        );
    }
}

export default withToastUI(connect(
    mapStateToProps,
    mapDispatchToProps
)(EditProperty));