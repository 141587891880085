import * as React from 'react';
import { Field, reduxForm, InjectedFormProps, formValueSelector } from 'redux-form';
import { renderInput, renderTextArea, renderFileInput, renderSelect } from '../components/FormElements';
import { required, uri, requiredFile, fileFormat } from '../util/Validation';
import { getFieldName, nameof } from '../util/Utils';
import { Form, Label, Button, FormGroup, Row, Col, FormText } from 'reactstrap';
import { connect } from 'react-redux';
import { Category } from '../contract/dto/Category';

export type CategoryFormFields = {
  parentId: number | null;
  imageUrl: string;
  imageFile: FileList;
  uri: string;
  name: string;
  description: string;
  summary: string;
  rawContent: string;
}

type CategoryFormFieldsProps = {
  categories: Category[];
  prefix?: string;
  imageUrl: string;
  imageFile: FileList;
};

export function CategoryFormFields(props: CategoryFormFieldsProps) {
  return (
    <React.Fragment>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="imageUrl">Görsel (URL)</Label>
            <Field
              id="imageUrl"
              name={`${getFieldName<CategoryFormFields>('imageUrl', props.prefix)}`}
              component={renderInput}
              label="Görsel (URL)"
              validate={(!props.imageFile || props.imageFile.length <= 0) ? [required] : undefined}
              disabled={props.imageFile && props.imageFile.length >= 1}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label
              for="imageFile"
            // className="btn btn-default btn-primary btn-sm"
            >Dosya Seç</Label>
            <Field
              id="imageFile"
              name={`${getFieldName<CategoryFormFields>('imageFile', props.prefix)}`}
              component={renderFileInput}
              label="Dosya"
              validate={(!props.imageUrl) ? [requiredFile, fileFormat] : undefined}
              type="file"
              accept=".jpg,.jpeg,.png,.pdf,image/jpeg,image/png,application/pdf"
            // className="d-none"
            />
            <FormText color="muted">
              Yalnızca jpg, png veya pdf formatı
            </FormText>
          </FormGroup>
        </Col>
      </Row>

      <FormGroup>
        <Label for="categoryId">Üst Kategori</Label>
        <Field
          id="categoryId"
          name={`${getFieldName<CategoryFormFields>('parentId', props.prefix)}`}
          component={renderSelect}
          label="Üst Kategori"
          validate={[]}
          children={<>
            <option>Seçiniz</option>
            {props.categories.map((category, i) => <option key={i} value={category.id}>{category.name}</option>)}
          </>}
        />
      </FormGroup>

      <FormGroup>
        <Label for="uri">URI</Label>
        <Field
          id="uri"
          name={`${getFieldName<CategoryFormFields>('uri', props.prefix)}`}
          component={renderInput}
          label="URI"
          validate={[required, uri]}
        />
      </FormGroup>

      <FormGroup>
        <Label for="name">Ad</Label>
        <Field
          id="name"
          name={`${getFieldName<CategoryFormFields>('name', props.prefix)}`}
          component={renderInput}
          label="Ad"
          validate={[required]}
        />
      </FormGroup>

      <FormGroup>
        <Label for="description">Açıklama</Label>
        <Field
          id="description"
          name={`${getFieldName<CategoryFormFields>('description', props.prefix)}`}
          component={renderInput}
          label="Açıklama"
        />
      </FormGroup>
      <FormGroup>
        <Label for="summary">Özet</Label>
        <Field
          id="summary"
          name={`${getFieldName<CategoryFormFields>('summary', props.prefix)}`}
          component={renderInput}
          label="Özet"
          validate={[required]}
        />
      </FormGroup>
      <FormGroup>
        <Label for="rawContent">İçerik</Label>
        <Field
          id="rawContent"
          name={`${getFieldName<CategoryFormFields>('rawContent', props.prefix)}`}
          component={renderTextArea}
          label="İçerik"
          validate={[required]}
        />
      </FormGroup>
    </React.Fragment>
  );
}

type _CategoryFormProps = CategoryFormFieldsProps & React.PropsWithChildren<{
  disabled?: boolean;
}>;

export type CategoryFormProps = InjectedFormProps<CategoryFormFields, _CategoryFormProps> &
  _CategoryFormProps;

class CategoryForm extends React.PureComponent<CategoryFormProps> {
  public render() {
    const { handleSubmit } = this.props;

    return (
      <Form onSubmit={(s) => handleSubmit(s)} noValidate disabled={this.props.disabled}>
        <CategoryFormFields
          categories={this.props.categories}
          prefix={this.props.prefix}
          imageFile={this.props.imageFile}
          imageUrl={this.props.imageUrl}
        />

        <Button type="submit" color="primary"><i className="fa fa-save mr-2"></i>Kaydet</Button>

        {this.props.children}
      </Form>
    );
  }
};

export const CategoryFormName: string = 'category';

const selector = formValueSelector(CategoryFormName);

export default connect(
  (state) => ({
    imageUrl: selector(state, nameof<CategoryFormFields>('imageUrl')),
    imageFile: selector(state, nameof<CategoryFormFields>('imageFile')),
  })
)(reduxForm<CategoryFormFields, _CategoryFormProps>({
  form: CategoryFormName
})(CategoryForm));